import React, { useEffect } from "react"
import * as buffer from 'buffer';
import { connect, keyStores, WalletConnection, Contract } from "near-api-js";
import { useAuth, userDataRef } from "../providers/AuthProvider";
import { Bounty } from "../types/Bounty";

import useEvent from "./useEvent";
import { useInfiniteQuery, useQuery } from "react-query";
import { NFTToken } from "../types/NFTToken";
import axios from "axios";
import { NEAR_API } from "../providers";
(window as any).Buffer = buffer.Buffer; // https://github.com/isaacs/core-util-is/issues/27#issuecomment-878969583

interface NearWalletContextInterface {
    walletConnection: React.MutableRefObject<WalletConnection | null>
}

const connectionConfig = {
    networkId: "testnet",
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
    nodeUrl: "https://rpc.testnet.near.org",
    walletUrl: "https://wallet.testnet.near.org",
    helperUrl: "https://helper.testnet.near.org",
    explorerUrl: "https://explorer.testnet.near.org",
    headers: {}
};

export let walletConnection = React.createRef<WalletConnection>()
export let contract = React.createRef<Contract>()

export let marketPlaceContract = React.createRef<Contract>()

export const useNearWallet = () => {
    const { user, loginWithNear, userData } = useAuth()

    const useInit = () => useEffect(() => {
        const initNear = async () => {
            // connect to NEAR
            const nearConnection = await connect(connectionConfig);
            // create wallet connection
            //@ts-ignore
            walletConnection.current = new WalletConnection(nearConnection, 'client-dashboard');



            //@ts-ignore
            contract.current = new Contract(
                walletConnection.current.account(),
                "carbongames.testnet",
                {
                    viewMethods: ["get_payments"],
                    changeMethods: ["payment", "add_event", "pool_funds", "add_bounty"],
                }
            )

            //@ts-ignore
            marketPlaceContract.current = new Contract(
                walletConnection.current.account(),
                "parisblockchain5.testnet",
                {
                    viewMethods: ["nft_tokens", "nft_tokens_for_owner"],
                    changeMethods: [],
                }
            )

            if (!user && walletConnection.current.isSignedIn()) {
                const account_id = walletConnection.current.getAccountId()
                console.log({ account_id })
                loginWithNear.mutate({ account_id }, { onError: () => walletConnection.current?.signOut() })
            }

        }

        initNear()
    }, [])


    return {

        useInit,

        signIn: async () => {
            await walletConnection.current?.requestSignIn({ contractId: 'carbongames.testnet' })
        },

        addEventToBlockchain: async (data: { event_id: string, title?: string, description?: string, latitude?: string, longitude?: string, }) => {
            //@ts-ignore
            return await contract.current.add_event({
                callbackUrl: `http://localhost:3000/?success=event_submitted&event_id=${data.event_id}`, // callbackUrl after the transaction approved (optional)
                args: data,
                gas: 300000000000000, // attached GAS (optional)
                amount: `1000000000000000000000000`, // attached deposit 1N (required),
            })
        },

        sponsorEvent: async ({ event_id, amount }: { event_id: string, amount: number }) => {
            //@ts-ignore
            await contract.current.pool_funds({
                callbackUrl: "", // callbackUrl after the transaction approved (optional)
                args: {
                    event_id: event_id,
                    amount
                },
                gas: 300000000000000, // attached GAS (optional)
                amount: `${amount}000000000000000000000000`, // attached deposit any amount (required)
            });
        },

        addBounty: async (data: Bounty) => {
            console.log(data)
            //@ts-ignore
            return await contract.current.add_bounty({
                callbackUrl: `http://localhost:3000/?success=event_bounty_created&event_id=${data.event_bounties[0].event_id}&amount=${data.event_bounties[0].amount / 2}`, // callbackUrl after the transaction approved (optional)
                args: {
                    "event_id": data.event_bounties[0].event_id,
                    "longitude": data.event_bounties[0].location.coordinates.longitude,
                    "latitude": data.event_bounties[0].location.coordinates.latitude,
                    "max_shared": data.max_shared,
                    "amount": data.event_bounties[0].amount
                },
                gas: 300000000000000, // attached GAS (optional)
                amount: `${data.event_bounties.map(e => e.amount).reduce((t, c) => (t || 0) + parseFloat((c || 0) + ''), 0)}000000000000000000000000`, // attached deposit 1N (required),
            })
        },

        useMyNftTokens: () => useQuery({
            queryKey: ['my-nft-tokens', user?.uid, userData?.near_wallet?.account_id],
            queryFn: async () => {
                //@ts-ignore
                const tokens = await marketPlaceContract.current.nft_tokens_for_owner({ account_id: userData?.near_wallet?.account_id })
                return (tokens || []).map((token: any) => new NFTToken(token)) as NFTToken[]
            },
            enabled: !!user
        }),

        useAllNFTs: () => useInfiniteQuery({
            queryKey: ['all-nfts'],
            queryFn: async ({ pageParam = 0 }) => {
                const res = await NEAR_API.post('near/list-nfts-marketplace', { from: "0", limit: 200 })
                console.log(res.data)
                const results: any[] = res.data.data
                console.log(results, res.data)
                return results.map((nft: any) => new NFTToken(nft))
            },
            getNextPageParam: (lastPage, allPages) => allPages.length,
            getPreviousPageParam: (firstPage, allPages) => allPages.length-1,
        })
    }
}