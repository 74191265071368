import { NFTToken } from "../../types/NFTToken"
import CustomCheckbox from "./CustomCheckbox"
import ImageIcon from "./ImageIcon"

const NFTTokenItem = ({ token, selected, onSelect }: { token: NFTToken, selected: boolean, onSelect: () => void }) => {


    return (
        <div onClick={onSelect} className="flex flex-col rounded-xl overflow-hidden cursor-pointer border border-slate-100 hover:border-gray-300 transition-all relative" >
            <img src={token.metadata.media} className="w-full h-[180px] object-cover" />
            <div className="bg-slate-100 flex flex-col p-4 items-start gap-1" >
                <div className="flex items-center gap-2" >
                    <ImageIcon size={16} name='verified' />
                    <p className="text-sm">{token.emission} kgs</p>
                </div>
                <div className="flex items-center gap-2" >
                    <ImageIcon size={16} name='calendar_date' />
                    <p className="text-sm" >{token.date}</p>
                </div>
                <div className="flex items-center gap-2" >
                    <ImageIcon size={16} name='location' />
                    <p className="text-sm">{token.city}</p>
                </div>
            </div>
            <div className="absolute top-4 right-4 bg-white rounded" >
                <CustomCheckbox onClick={onSelect} value={selected} containerStyle={{ marginTop: -3 }} />
            </div>

        </div >
    )
}

export default NFTTokenItem