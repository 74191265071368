import React, { useEffect, useMemo } from 'react'
import { GoogleMap, Polyline, useJsApiLoader, } from '@react-google-maps/api';
import Constants from '../../common/Constants';
import CarMarker from '../Markers/CarMarker';
import { Marker as MarkerType } from '../../types/Marker'
import { Carpool } from '../../types/Carpool';
import { mapStyle } from '../../assets/mapStyles';
import EventMarker from '../Markers/EventMarker';
import { createGlobalState } from 'react-use';
import CorporateBranchMarker from '../Markers/CorporateBranchMarker';
import useGeolocation from '../../hooks/useGeolocation';


export const useMapCenter = createGlobalState({ lat: 38.738940, lng: -9.152631 })
export const useMap = createGlobalState<google.maps.Map | null>(null)

const libraries: any = ['drawing', 'geometry', 'localContext', 'places', 'visualization']

type Props = {
    markers?: MarkerType[],
    polylines?: ({ lat: number, lng: number }[] | null | undefined)
}

function MapComponent({
    markers = [],
    polylines
}: Props) {
    const [map, setMap] = useMap()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        libraries,
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!
    })
    const { getCurrentPosition } = useGeolocation()

    useEffect(() => {
        if (!map) return
        if(markers.length===0) getCurrentPosition().then(({ latitude, longitude }) => map.setCenter({ lat: latitude, lng: longitude }))
    }, [map])

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // map.setCenter(center)
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
        setMap(null)
    }, [])

    useEffect(() => {
        if (!map || !markers || markers.length === 0) return

        const nonOverlappingMarkers = markers.filter((m, i, arr)=>arr.map(m=>m.location.coordinates.latitude).indexOf(m.location.coordinates.latitude)===i)

        if (nonOverlappingMarkers.length === 1) return map.moveCamera({ center: { lat: markers[0].location.coordinates.latitude, lng: markers[0].location.coordinates.longitude }, zoom: 15 })

        const bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < markers.length; i++) {
            const marker = markers[i];
            const newPoint = new google.maps.LatLng(marker.location.coordinates.latitude, marker.location.coordinates.longitude);
            bounds.extend(newPoint);
        }


        map.fitBounds(bounds, 100)
        // if ((map.getZoom() || 0) > 14) map.setZoom(14)

    }, [!!map, markers.length])

    if (!isLoaded) return null



    return (
        <GoogleMap

            mapContainerStyle={{ position: 'absolute', top: Constants.header_height, left: 0, bottom: 0, right: 0, }}
            // center={{ lat: geolocation.latitude, lng: geolocation.longitude }}
            zoom={14}
            options={{
                styles: mapStyle,
                fullscreenControl: false,
                streetViewControl: false,
                zoomControl: false,
                mapTypeControl: false
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >

            {/* markers */}
            {markers.map((marker, index) => <CustomMarker marker={marker} key={index} />)}

            {/* polyline */}
            <Polyline
                path={polylines || []}
                options={{ strokeWeight: 4 }}
            />


        </GoogleMap >
    )
}

export default React.memo(MapComponent)

const CustomMarker = ({ marker }: { marker: MarkerType }) => {
    switch (marker.data?.type || marker.type) {
        case 'carpool': return <CarMarker marker={marker} />
        case 'event': return <EventMarker marker={marker} />
        case 'corporate-branch': return <CorporateBranchMarker marker={marker} />
        default: return null
    }
}






const carpool = new Carpool({
    "raw": {
        "id": "8333216178162",
        "directions": {
            "routes": [
                {
                    "bounds": {
                        "northeast": {
                            "lat": 38.7348098,
                            "lng": -9.144371200000002
                        },
                        "southwest": {
                            "lat": 38.7298361,
                            "lng": -9.1527925
                        }
                    },
                    "overview_polyline": {
                        "points": "olkkFzizv@}H|GGHUXSUGK_AgBMYEG]c@[m@j@a@_@y@?KR}@pCaKd@eB`A{Dt@iCkJ{EoBcAa@UIK@M?[CYSe@OK[IW?OBURQZER?BYIM?cBZ"
                    },
                    "legs": [
                        {
                            "duration": {
                                "text": "1 min",
                                "value": 0
                            },
                            "start_location": {
                                "lng": -9.1511788,
                                "lat": 38.7298361
                            },
                            "traffic_speed_entry": [],
                            "end_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                            "distance": {
                                "value": 0,
                                "text": "1 m"
                            },
                            "steps": [
                                {
                                    "distance": {
                                        "value": 0,
                                        "text": "1 m"
                                    },
                                    "end_location": {
                                        "lng": -9.1511788,
                                        "lat": 38.7298361
                                    },
                                    "start_location": {
                                        "lat": 38.7298361,
                                        "lng": -9.1511788
                                    },
                                    "polyline": {
                                        "points": "olkkFzizv@"
                                    },
                                    "html_instructions": "Head on <b>Av. Sidónio Pais</b>",
                                    "travel_mode": "DRIVING",
                                    "duration": {
                                        "text": "1 min",
                                        "value": 0
                                    }
                                }
                            ],
                            "via_waypoint": [],
                            "start_address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                            "end_location": {
                                "lng": -9.1511788,
                                "lat": 38.7298361
                            }
                        },
                        {
                            "end_address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                            "traffic_speed_entry": [],
                            "distance": {
                                "text": "1.4 km",
                                "value": 1411
                            },
                            "steps": [
                                {
                                    "travel_mode": "DRIVING",
                                    "end_location": {
                                        "lat": 38.7315755,
                                        "lng": -9.1527925
                                    },
                                    "polyline": {
                                        "points": "olkkFzizv@sBjBuD~CIFIHGHUX"
                                    },
                                    "start_location": {
                                        "lat": 38.7298361,
                                        "lng": -9.1511788
                                    },
                                    "duration": {
                                        "text": "1 min",
                                        "value": 57
                                    },
                                    "distance": {
                                        "text": "0.2 km",
                                        "value": 239
                                    },
                                    "html_instructions": "Head <b>northwest</b> on <b>Av. Sidónio Pais</b> toward <b>Alameda Cardeal Cerejeira</b>"
                                },
                                {
                                    "end_location": {
                                        "lng": -9.152101,
                                        "lat": 38.732039
                                    },
                                    "duration": {
                                        "value": 32,
                                        "text": "1 min"
                                    },
                                    "travel_mode": "DRIVING",
                                    "distance": {
                                        "value": 79,
                                        "text": "79 m"
                                    },
                                    "maneuver": "turn-right",
                                    "start_location": {
                                        "lng": -9.1527925,
                                        "lat": 38.7315755
                                    },
                                    "html_instructions": "Turn <b>right</b> onto <b>R. Eng. Canto Resende</b>",
                                    "polyline": {
                                        "points": "kwkkF|szv@SUEGACQ[m@kA"
                                    }
                                },
                                {
                                    "distance": {
                                        "value": 67,
                                        "text": "67 m"
                                    },
                                    "polyline": {
                                        "points": "gzkkFrozv@CIAECECCAAAA?AAAGGEGGGGKKSOY"
                                    },
                                    "end_location": {
                                        "lat": 38.7324259,
                                        "lng": -9.1515179
                                    },
                                    "travel_mode": "DRIVING",
                                    "html_instructions": "Continue onto <b>R. Augusto dos Santos</b>",
                                    "start_location": {
                                        "lng": -9.152101,
                                        "lat": 38.732039
                                    },
                                    "duration": {
                                        "value": 25,
                                        "text": "1 min"
                                    }
                                },
                                {
                                    "end_location": {
                                        "lng": -9.151345899999999,
                                        "lat": 38.7322141
                                    },
                                    "travel_mode": "DRIVING",
                                    "maneuver": "turn-right",
                                    "polyline": {
                                        "points": "u|kkF~kzv@XSPM"
                                    },
                                    "html_instructions": "Turn <b>right</b> onto <b>Largo de São Sebastião da Pedreira</b>",
                                    "start_location": {
                                        "lng": -9.1515179,
                                        "lat": 38.7324259
                                    },
                                    "duration": {
                                        "text": "1 min",
                                        "value": 8
                                    },
                                    "distance": {
                                        "text": "28 m",
                                        "value": 28
                                    }
                                },
                                {
                                    "end_location": {
                                        "lat": 38.730793,
                                        "lng": -9.1467329
                                    },
                                    "maneuver": "turn-left",
                                    "distance": {
                                        "text": "0.4 km",
                                        "value": 445
                                    },
                                    "polyline": {
                                        "points": "i{kkF|jzv@Sc@KU?E?EBMDSDQBI^qAf@mBf@cB`@}A@IDI\\qAVgAFYFSXeAZiABIL_@"
                                    },
                                    "duration": {
                                        "value": 118,
                                        "text": "2 mins"
                                    },
                                    "start_location": {
                                        "lng": -9.151345899999999,
                                        "lat": 38.7322141
                                    },
                                    "html_instructions": "Turn <b>left</b> onto <b>R. Tomás Ribeiro</b>",
                                    "travel_mode": "DRIVING"
                                },
                                {
                                    "polyline": {
                                        "points": "mrkkF`nyv@FUsDiBa@UyBkA[O_@SYM?Au@_@YOGEIK"
                                    },
                                    "end_location": {
                                        "lng": -9.145008599999999,
                                        "lat": 38.733353
                                    },
                                    "travel_mode": "DRIVING",
                                    "distance": {
                                        "text": "0.3 km",
                                        "value": 333
                                    },
                                    "maneuver": "turn-left",
                                    "html_instructions": "Turn <b>left</b> onto <b>Av. Fontes Pereira de Melo</b>",
                                    "start_location": {
                                        "lng": -9.1467329,
                                        "lat": 38.730793
                                    },
                                    "duration": {
                                        "text": "1 min",
                                        "value": 60
                                    }
                                },
                                {
                                    "maneuver": "turn-right",
                                    "polyline": {
                                        "points": "mblkFhcyv@@M@MAMAMAKEMEKEIAAA?AA?AAAA??AAAEAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?B"
                                    },
                                    "end_location": {
                                        "lng": -9.144753999999999,
                                        "lat": 38.7341143
                                    },
                                    "start_location": {
                                        "lat": 38.733353,
                                        "lng": -9.145008599999999
                                    },
                                    "distance": {
                                        "text": "0.1 km",
                                        "value": 141
                                    },
                                    "html_instructions": "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                    "duration": {
                                        "text": "1 min",
                                        "value": 58
                                    },
                                    "travel_mode": "DRIVING"
                                },
                                {
                                    "end_location": {
                                        "lat": 38.7348098,
                                        "lng": -9.1448403
                                    },
                                    "distance": {
                                        "value": 79,
                                        "text": "79 m"
                                    },
                                    "travel_mode": "DRIVING",
                                    "polyline": {
                                        "points": "eglkFtayv@YIA?K?cBZ"
                                    },
                                    "start_location": {
                                        "lat": 38.7341143,
                                        "lng": -9.144753999999999
                                    },
                                    "html_instructions": "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
                                    "duration": {
                                        "text": "1 min",
                                        "value": 28
                                    },
                                    "maneuver": "turn-right"
                                }
                            ],
                            "start_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                            "via_waypoint": [],
                            "end_location": {
                                "lng": -9.1448403,
                                "lat": 38.7348098
                            },
                            "start_location": {
                                "lat": 38.7298361,
                                "lng": -9.1511788
                            },
                            "duration": {
                                "value": 386,
                                "text": "6 mins"
                            }
                        }
                    ],
                    "waypoint_order": [
                        0
                    ],
                    "copyrights": "Map data ©2022 Google",
                    "warnings": [],
                    "summary": "Av. Sidónio Pais"
                }
            ],
            "geocoded_waypoints": [
                {
                    "geocoder_status": "OK",
                    "place_id": "ChIJkzYGeHQzGQ0RNRppJyBSIkw",
                    "types": [
                        "street_address"
                    ]
                },
                {
                    "place_id": "ChIJvXvq83MzGQ0RbjOYybAvPE4",
                    "geocoder_status": "OK",
                    "types": [
                        "premise"
                    ]
                },
                {
                    "geocoder_status": "OK",
                    "types": [
                        "establishment",
                        "point_of_interest",
                        "real_estate_agency"
                    ],
                    "place_id": "ChIJJSaMEqAzGQ0RSSzMTk-fmaU"
                }
            ],
            "status": "OK"
        },
        "preferences": {
            "female_passengers_only": null,
            "verified_passengers_only": null
        },
        "co2Amount": 0.5798745402740049,
        "origin": {
            "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
            "coordinates": {
                "longitude": -9.1514926,
                "latitude": 38.7296842
            },
            "geohash": "eyckrf80fb"
        },
        "owner": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
        "skin": null,
        "status": "completed",
        "destination": {
            "address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
            "geohash": "eyckrgkywv",
            "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
            "type": "home",
            "coordinates": {
                "latitude": 38.7348175,
                "longitude": -9.1447944
            }
        },
        "rules": [
            "Keep off the radio",
            "No smoking",
            "No eating or drinking"
        ],
        "car": {
            "model": "AZERA",
            "make": "Hyundai",
            "type": "carbon-emitting",
            "year": 2013,
            "co2_p_km": 0.20599450809023268,
            "seat": 3
        },
        "passengers": {
            "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3": {
                "userId": "user_id",
                "name": "Adisi Karas",
                "location": {
                    "address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                    "coordinates": {
                        "latitude": 38.72955322265625,
                        "longitude": -9.151782318958222
                    },
                    "geohash": "eyckrdrzup"
                },
                "credits": 8.6,
                "co2Amount": 0.2896282783748671,
                "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_white0320.png",
                "seat": 1,
                "id": "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3",
                "wallet_id": null,
                "status": "picked-up",
                "carpool": {
                    "id": "8333216178162",
                    "directions": {
                        "routes": [
                            {
                                "bounds": {
                                    "northeast": {
                                        "lat": 38.7348098,
                                        "lng": -9.144371200000002
                                    },
                                    "southwest": {
                                        "lat": 38.7298361,
                                        "lng": -9.1527925
                                    }
                                },
                                "overview_polyline": {
                                    "points": "olkkFzizv@}H|GGHUXSUGK_AgBMYEG]c@[m@j@a@_@y@?KR}@pCaKd@eB`A{Dt@iCkJ{EoBcAa@UIK@M?[CYSe@OK[IW?OBURQZER?BYIM?cBZ"
                                },
                                "legs": [
                                    {
                                        "duration": {
                                            "text": "1 min",
                                            "value": 0
                                        },
                                        "start_location": {
                                            "lng": -9.1511788,
                                            "lat": 38.7298361
                                        },
                                        "traffic_speed_entry": [],
                                        "end_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                        "distance": {
                                            "value": 0,
                                            "text": "1 m"
                                        },
                                        "steps": [
                                            {
                                                "distance": {
                                                    "value": 0,
                                                    "text": "1 m"
                                                },
                                                "end_location": {
                                                    "lng": -9.1511788,
                                                    "lat": 38.7298361
                                                },
                                                "start_location": {
                                                    "lat": 38.7298361,
                                                    "lng": -9.1511788
                                                },
                                                "polyline": {
                                                    "points": "olkkFzizv@"
                                                },
                                                "html_instructions": "Head on <b>Av. Sidónio Pais</b>",
                                                "travel_mode": "DRIVING",
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 0
                                                }
                                            }
                                        ],
                                        "via_waypoint": [],
                                        "start_address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                        "end_location": {
                                            "lng": -9.1511788,
                                            "lat": 38.7298361
                                        }
                                    },
                                    {
                                        "end_address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                                        "traffic_speed_entry": [],
                                        "distance": {
                                            "text": "1.4 km",
                                            "value": 1411
                                        },
                                        "steps": [
                                            {
                                                "travel_mode": "DRIVING",
                                                "end_location": {
                                                    "lat": 38.7315755,
                                                    "lng": -9.1527925
                                                },
                                                "polyline": {
                                                    "points": "olkkFzizv@sBjBuD~CIFIHGHUX"
                                                },
                                                "start_location": {
                                                    "lat": 38.7298361,
                                                    "lng": -9.1511788
                                                },
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 57
                                                },
                                                "distance": {
                                                    "text": "0.2 km",
                                                    "value": 239
                                                },
                                                "html_instructions": "Head <b>northwest</b> on <b>Av. Sidónio Pais</b> toward <b>Alameda Cardeal Cerejeira</b>"
                                            },
                                            {
                                                "end_location": {
                                                    "lng": -9.152101,
                                                    "lat": 38.732039
                                                },
                                                "duration": {
                                                    "value": 32,
                                                    "text": "1 min"
                                                },
                                                "travel_mode": "DRIVING",
                                                "distance": {
                                                    "value": 79,
                                                    "text": "79 m"
                                                },
                                                "maneuver": "turn-right",
                                                "start_location": {
                                                    "lng": -9.1527925,
                                                    "lat": 38.7315755
                                                },
                                                "html_instructions": "Turn <b>right</b> onto <b>R. Eng. Canto Resende</b>",
                                                "polyline": {
                                                    "points": "kwkkF|szv@SUEGACQ[m@kA"
                                                }
                                            },
                                            {
                                                "distance": {
                                                    "value": 67,
                                                    "text": "67 m"
                                                },
                                                "polyline": {
                                                    "points": "gzkkFrozv@CIAECECCAAAA?AAAGGEGGGGKKSOY"
                                                },
                                                "end_location": {
                                                    "lat": 38.7324259,
                                                    "lng": -9.1515179
                                                },
                                                "travel_mode": "DRIVING",
                                                "html_instructions": "Continue onto <b>R. Augusto dos Santos</b>",
                                                "start_location": {
                                                    "lng": -9.152101,
                                                    "lat": 38.732039
                                                },
                                                "duration": {
                                                    "value": 25,
                                                    "text": "1 min"
                                                }
                                            },
                                            {
                                                "end_location": {
                                                    "lng": -9.151345899999999,
                                                    "lat": 38.7322141
                                                },
                                                "travel_mode": "DRIVING",
                                                "maneuver": "turn-right",
                                                "polyline": {
                                                    "points": "u|kkF~kzv@XSPM"
                                                },
                                                "html_instructions": "Turn <b>right</b> onto <b>Largo de São Sebastião da Pedreira</b>",
                                                "start_location": {
                                                    "lng": -9.1515179,
                                                    "lat": 38.7324259
                                                },
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 8
                                                },
                                                "distance": {
                                                    "text": "28 m",
                                                    "value": 28
                                                }
                                            },
                                            {
                                                "end_location": {
                                                    "lat": 38.730793,
                                                    "lng": -9.1467329
                                                },
                                                "maneuver": "turn-left",
                                                "distance": {
                                                    "text": "0.4 km",
                                                    "value": 445
                                                },
                                                "polyline": {
                                                    "points": "i{kkF|jzv@Sc@KU?E?EBMDSDQBI^qAf@mBf@cB`@}A@IDI\\qAVgAFYFSXeAZiABIL_@"
                                                },
                                                "duration": {
                                                    "value": 118,
                                                    "text": "2 mins"
                                                },
                                                "start_location": {
                                                    "lng": -9.151345899999999,
                                                    "lat": 38.7322141
                                                },
                                                "html_instructions": "Turn <b>left</b> onto <b>R. Tomás Ribeiro</b>",
                                                "travel_mode": "DRIVING"
                                            },
                                            {
                                                "polyline": {
                                                    "points": "mrkkF`nyv@FUsDiBa@UyBkA[O_@SYM?Au@_@YOGEIK"
                                                },
                                                "end_location": {
                                                    "lng": -9.145008599999999,
                                                    "lat": 38.733353
                                                },
                                                "travel_mode": "DRIVING",
                                                "distance": {
                                                    "text": "0.3 km",
                                                    "value": 333
                                                },
                                                "maneuver": "turn-left",
                                                "html_instructions": "Turn <b>left</b> onto <b>Av. Fontes Pereira de Melo</b>",
                                                "start_location": {
                                                    "lng": -9.1467329,
                                                    "lat": 38.730793
                                                },
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 60
                                                }
                                            },
                                            {
                                                "maneuver": "turn-right",
                                                "polyline": {
                                                    "points": "mblkFhcyv@@M@MAMAMAKEMEKEIAAA?AA?AAAA??AAAEAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?B"
                                                },
                                                "end_location": {
                                                    "lng": -9.144753999999999,
                                                    "lat": 38.7341143
                                                },
                                                "start_location": {
                                                    "lat": 38.733353,
                                                    "lng": -9.145008599999999
                                                },
                                                "distance": {
                                                    "text": "0.1 km",
                                                    "value": 141
                                                },
                                                "html_instructions": "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 58
                                                },
                                                "travel_mode": "DRIVING"
                                            },
                                            {
                                                "end_location": {
                                                    "lat": 38.7348098,
                                                    "lng": -9.1448403
                                                },
                                                "distance": {
                                                    "value": 79,
                                                    "text": "79 m"
                                                },
                                                "travel_mode": "DRIVING",
                                                "polyline": {
                                                    "points": "eglkFtayv@YIA?K?cBZ"
                                                },
                                                "start_location": {
                                                    "lat": 38.7341143,
                                                    "lng": -9.144753999999999
                                                },
                                                "html_instructions": "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 28
                                                },
                                                "maneuver": "turn-right"
                                            }
                                        ],
                                        "start_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                        "via_waypoint": [],
                                        "end_location": {
                                            "lng": -9.1448403,
                                            "lat": 38.7348098
                                        },
                                        "start_location": {
                                            "lat": 38.7298361,
                                            "lng": -9.1511788
                                        },
                                        "duration": {
                                            "value": 386,
                                            "text": "6 mins"
                                        }
                                    }
                                ],
                                "waypoint_order": [
                                    0
                                ],
                                "copyrights": "Map data ©2022 Google",
                                "warnings": [],
                                "summary": "Av. Sidónio Pais"
                            }
                        ],
                        "geocoded_waypoints": [
                            {
                                "geocoder_status": "OK",
                                "place_id": "ChIJkzYGeHQzGQ0RNRppJyBSIkw",
                                "types": [
                                    "street_address"
                                ]
                            },
                            {
                                "place_id": "ChIJvXvq83MzGQ0RbjOYybAvPE4",
                                "geocoder_status": "OK",
                                "types": [
                                    "premise"
                                ]
                            },
                            {
                                "geocoder_status": "OK",
                                "types": [
                                    "establishment",
                                    "point_of_interest",
                                    "real_estate_agency"
                                ],
                                "place_id": "ChIJJSaMEqAzGQ0RSSzMTk-fmaU"
                            }
                        ],
                        "status": "OK"
                    },
                    "preferences": {
                        "female_passengers_only": null,
                        "verified_passengers_only": null
                    },
                    "co2Amount": 0.5798745402740049,
                    "origin": {
                        "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                        "coordinates": {
                            "longitude": -9.1514926,
                            "latitude": 38.7296842
                        },
                        "geohash": "eyckrf80fb"
                    },
                    "owner": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                    "skin": null,
                    "status": "completed",
                    "destination": {
                        "address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                        "geohash": "eyckrgkywv",
                        "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                        "type": "home",
                        "coordinates": {
                            "latitude": 38.7348175,
                            "longitude": -9.1447944
                        }
                    },
                    "rules": [
                        "Keep off the radio",
                        "No smoking",
                        "No eating or drinking"
                    ],
                    "car": {
                        "model": "AZERA",
                        "make": "Hyundai",
                        "type": "carbon-emitting",
                        "year": 2013,
                        "co2_p_km": 0.20599450809023268,
                        "seat": 3
                    },
                    "createdAt": 1666783821838,
                    "passengersArray": [
                        {
                            "id": "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3",
                            "seat": 1,
                            "co2Amount": 0.2896282783748671,
                            "credits": 8.6,
                            "wallet_id": null,
                            "status": "picked-up",
                            "name": "Adisi Karas",
                            "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_white0320.png",
                            "location": {
                                "address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                "coordinates": {
                                    "latitude": 38.72955322265625,
                                    "longitude": -9.151782318958222
                                },
                                "geohash": "eyckrdrzup"
                            }
                        },
                        {
                            "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_brown536.png",
                            "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                            "location": {
                                "coordinates": {
                                    "latitude": 38.7296842,
                                    "longitude": -9.1514926
                                },
                                "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                "geohash": "eyckrf80fb"
                            },
                            "wallet_id": "mikki.thecarbongames.testnet",
                            "co2Amount": 0.2902462618991378,
                            "seat": 0,
                            "name": "Nikki",
                            "credits": 17.2
                        }
                    ]
                },
                "type": "carpool_passenger"
            },
            "79xk72qzvFQvUtWH4Ed2gdLq8Yf1": {
                "userId": "user_id",
                "name": "Nikki",
                "location": {
                    "coordinates": {
                        "latitude": 38.7296842,
                        "longitude": -9.1514926
                    },
                    "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                    "geohash": "eyckrf80fb"
                },
                "credits": 17.2,
                "co2Amount": 0.2902462618991378,
                "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_brown536.png",
                "seat": 0,
                "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                "wallet_id": "mikki.thecarbongames.testnet",
                "carpool": {
                    "id": "8333216178162",
                    "directions": {
                        "routes": [
                            {
                                "bounds": {
                                    "northeast": {
                                        "lat": 38.7348098,
                                        "lng": -9.144371200000002
                                    },
                                    "southwest": {
                                        "lat": 38.7298361,
                                        "lng": -9.1527925
                                    }
                                },
                                "overview_polyline": {
                                    "points": "olkkFzizv@}H|GGHUXSUGK_AgBMYEG]c@[m@j@a@_@y@?KR}@pCaKd@eB`A{Dt@iCkJ{EoBcAa@UIK@M?[CYSe@OK[IW?OBURQZER?BYIM?cBZ"
                                },
                                "legs": [
                                    {
                                        "duration": {
                                            "text": "1 min",
                                            "value": 0
                                        },
                                        "start_location": {
                                            "lng": -9.1511788,
                                            "lat": 38.7298361
                                        },
                                        "traffic_speed_entry": [],
                                        "end_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                        "distance": {
                                            "value": 0,
                                            "text": "1 m"
                                        },
                                        "steps": [
                                            {
                                                "distance": {
                                                    "value": 0,
                                                    "text": "1 m"
                                                },
                                                "end_location": {
                                                    "lng": -9.1511788,
                                                    "lat": 38.7298361
                                                },
                                                "start_location": {
                                                    "lat": 38.7298361,
                                                    "lng": -9.1511788
                                                },
                                                "polyline": {
                                                    "points": "olkkFzizv@"
                                                },
                                                "html_instructions": "Head on <b>Av. Sidónio Pais</b>",
                                                "travel_mode": "DRIVING",
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 0
                                                }
                                            }
                                        ],
                                        "via_waypoint": [],
                                        "start_address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                        "end_location": {
                                            "lng": -9.1511788,
                                            "lat": 38.7298361
                                        }
                                    },
                                    {
                                        "end_address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                                        "traffic_speed_entry": [],
                                        "distance": {
                                            "text": "1.4 km",
                                            "value": 1411
                                        },
                                        "steps": [
                                            {
                                                "travel_mode": "DRIVING",
                                                "end_location": {
                                                    "lat": 38.7315755,
                                                    "lng": -9.1527925
                                                },
                                                "polyline": {
                                                    "points": "olkkFzizv@sBjBuD~CIFIHGHUX"
                                                },
                                                "start_location": {
                                                    "lat": 38.7298361,
                                                    "lng": -9.1511788
                                                },
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 57
                                                },
                                                "distance": {
                                                    "text": "0.2 km",
                                                    "value": 239
                                                },
                                                "html_instructions": "Head <b>northwest</b> on <b>Av. Sidónio Pais</b> toward <b>Alameda Cardeal Cerejeira</b>"
                                            },
                                            {
                                                "end_location": {
                                                    "lng": -9.152101,
                                                    "lat": 38.732039
                                                },
                                                "duration": {
                                                    "value": 32,
                                                    "text": "1 min"
                                                },
                                                "travel_mode": "DRIVING",
                                                "distance": {
                                                    "value": 79,
                                                    "text": "79 m"
                                                },
                                                "maneuver": "turn-right",
                                                "start_location": {
                                                    "lng": -9.1527925,
                                                    "lat": 38.7315755
                                                },
                                                "html_instructions": "Turn <b>right</b> onto <b>R. Eng. Canto Resende</b>",
                                                "polyline": {
                                                    "points": "kwkkF|szv@SUEGACQ[m@kA"
                                                }
                                            },
                                            {
                                                "distance": {
                                                    "value": 67,
                                                    "text": "67 m"
                                                },
                                                "polyline": {
                                                    "points": "gzkkFrozv@CIAECECCAAAA?AAAGGEGGGGKKSOY"
                                                },
                                                "end_location": {
                                                    "lat": 38.7324259,
                                                    "lng": -9.1515179
                                                },
                                                "travel_mode": "DRIVING",
                                                "html_instructions": "Continue onto <b>R. Augusto dos Santos</b>",
                                                "start_location": {
                                                    "lng": -9.152101,
                                                    "lat": 38.732039
                                                },
                                                "duration": {
                                                    "value": 25,
                                                    "text": "1 min"
                                                }
                                            },
                                            {
                                                "end_location": {
                                                    "lng": -9.151345899999999,
                                                    "lat": 38.7322141
                                                },
                                                "travel_mode": "DRIVING",
                                                "maneuver": "turn-right",
                                                "polyline": {
                                                    "points": "u|kkF~kzv@XSPM"
                                                },
                                                "html_instructions": "Turn <b>right</b> onto <b>Largo de São Sebastião da Pedreira</b>",
                                                "start_location": {
                                                    "lng": -9.1515179,
                                                    "lat": 38.7324259
                                                },
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 8
                                                },
                                                "distance": {
                                                    "text": "28 m",
                                                    "value": 28
                                                }
                                            },
                                            {
                                                "end_location": {
                                                    "lat": 38.730793,
                                                    "lng": -9.1467329
                                                },
                                                "maneuver": "turn-left",
                                                "distance": {
                                                    "text": "0.4 km",
                                                    "value": 445
                                                },
                                                "polyline": {
                                                    "points": "i{kkF|jzv@Sc@KU?E?EBMDSDQBI^qAf@mBf@cB`@}A@IDI\\qAVgAFYFSXeAZiABIL_@"
                                                },
                                                "duration": {
                                                    "value": 118,
                                                    "text": "2 mins"
                                                },
                                                "start_location": {
                                                    "lng": -9.151345899999999,
                                                    "lat": 38.7322141
                                                },
                                                "html_instructions": "Turn <b>left</b> onto <b>R. Tomás Ribeiro</b>",
                                                "travel_mode": "DRIVING"
                                            },
                                            {
                                                "polyline": {
                                                    "points": "mrkkF`nyv@FUsDiBa@UyBkA[O_@SYM?Au@_@YOGEIK"
                                                },
                                                "end_location": {
                                                    "lng": -9.145008599999999,
                                                    "lat": 38.733353
                                                },
                                                "travel_mode": "DRIVING",
                                                "distance": {
                                                    "text": "0.3 km",
                                                    "value": 333
                                                },
                                                "maneuver": "turn-left",
                                                "html_instructions": "Turn <b>left</b> onto <b>Av. Fontes Pereira de Melo</b>",
                                                "start_location": {
                                                    "lng": -9.1467329,
                                                    "lat": 38.730793
                                                },
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 60
                                                }
                                            },
                                            {
                                                "maneuver": "turn-right",
                                                "polyline": {
                                                    "points": "mblkFhcyv@@M@MAMAMAKEMEKEIAAA?AA?AAAA??AAAEAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?B"
                                                },
                                                "end_location": {
                                                    "lng": -9.144753999999999,
                                                    "lat": 38.7341143
                                                },
                                                "start_location": {
                                                    "lat": 38.733353,
                                                    "lng": -9.145008599999999
                                                },
                                                "distance": {
                                                    "text": "0.1 km",
                                                    "value": 141
                                                },
                                                "html_instructions": "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 58
                                                },
                                                "travel_mode": "DRIVING"
                                            },
                                            {
                                                "end_location": {
                                                    "lat": 38.7348098,
                                                    "lng": -9.1448403
                                                },
                                                "distance": {
                                                    "value": 79,
                                                    "text": "79 m"
                                                },
                                                "travel_mode": "DRIVING",
                                                "polyline": {
                                                    "points": "eglkFtayv@YIA?K?cBZ"
                                                },
                                                "start_location": {
                                                    "lat": 38.7341143,
                                                    "lng": -9.144753999999999
                                                },
                                                "html_instructions": "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 28
                                                },
                                                "maneuver": "turn-right"
                                            }
                                        ],
                                        "start_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                        "via_waypoint": [],
                                        "end_location": {
                                            "lng": -9.1448403,
                                            "lat": 38.7348098
                                        },
                                        "start_location": {
                                            "lat": 38.7298361,
                                            "lng": -9.1511788
                                        },
                                        "duration": {
                                            "value": 386,
                                            "text": "6 mins"
                                        }
                                    }
                                ],
                                "waypoint_order": [
                                    0
                                ],
                                "copyrights": "Map data ©2022 Google",
                                "warnings": [],
                                "summary": "Av. Sidónio Pais"
                            }
                        ],
                        "geocoded_waypoints": [
                            {
                                "geocoder_status": "OK",
                                "place_id": "ChIJkzYGeHQzGQ0RNRppJyBSIkw",
                                "types": [
                                    "street_address"
                                ]
                            },
                            {
                                "place_id": "ChIJvXvq83MzGQ0RbjOYybAvPE4",
                                "geocoder_status": "OK",
                                "types": [
                                    "premise"
                                ]
                            },
                            {
                                "geocoder_status": "OK",
                                "types": [
                                    "establishment",
                                    "point_of_interest",
                                    "real_estate_agency"
                                ],
                                "place_id": "ChIJJSaMEqAzGQ0RSSzMTk-fmaU"
                            }
                        ],
                        "status": "OK"
                    },
                    "preferences": {
                        "female_passengers_only": null,
                        "verified_passengers_only": null
                    },
                    "co2Amount": 0.5798745402740049,
                    "origin": {
                        "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                        "coordinates": {
                            "longitude": -9.1514926,
                            "latitude": 38.7296842
                        },
                        "geohash": "eyckrf80fb"
                    },
                    "owner": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                    "skin": null,
                    "status": "completed",
                    "destination": {
                        "address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                        "geohash": "eyckrgkywv",
                        "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                        "type": "home",
                        "coordinates": {
                            "latitude": 38.7348175,
                            "longitude": -9.1447944
                        }
                    },
                    "rules": [
                        "Keep off the radio",
                        "No smoking",
                        "No eating or drinking"
                    ],
                    "car": {
                        "model": "AZERA",
                        "make": "Hyundai",
                        "type": "carbon-emitting",
                        "year": 2013,
                        "co2_p_km": 0.20599450809023268,
                        "seat": 3
                    },
                    "createdAt": 1666783821838,
                    "passengersArray": [
                        {
                            "userId": "user_id",
                            "name": "Adisi Karas",
                            "location": {
                                "address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                "coordinates": {
                                    "latitude": 38.72955322265625,
                                    "longitude": -9.151782318958222
                                },
                                "geohash": "eyckrdrzup"
                            },
                            "credits": 8.6,
                            "co2Amount": 0.2896282783748671,
                            "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_white0320.png",
                            "seat": 1,
                            "id": "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3",
                            "wallet_id": null,
                            "status": "picked-up",
                            "carpool": {
                                "id": "8333216178162",
                                "directions": {
                                    "routes": [
                                        {
                                            "bounds": {
                                                "northeast": {
                                                    "lat": 38.7348098,
                                                    "lng": -9.144371200000002
                                                },
                                                "southwest": {
                                                    "lat": 38.7298361,
                                                    "lng": -9.1527925
                                                }
                                            },
                                            "overview_polyline": {
                                                "points": "olkkFzizv@}H|GGHUXSUGK_AgBMYEG]c@[m@j@a@_@y@?KR}@pCaKd@eB`A{Dt@iCkJ{EoBcAa@UIK@M?[CYSe@OK[IW?OBURQZER?BYIM?cBZ"
                                            },
                                            "legs": [
                                                {
                                                    "duration": {
                                                        "text": "1 min",
                                                        "value": 0
                                                    },
                                                    "start_location": {
                                                        "lng": -9.1511788,
                                                        "lat": 38.7298361
                                                    },
                                                    "traffic_speed_entry": [],
                                                    "end_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                                    "distance": {
                                                        "value": 0,
                                                        "text": "1 m"
                                                    },
                                                    "steps": [
                                                        {
                                                            "distance": {
                                                                "value": 0,
                                                                "text": "1 m"
                                                            },
                                                            "end_location": {
                                                                "lng": -9.1511788,
                                                                "lat": 38.7298361
                                                            },
                                                            "start_location": {
                                                                "lat": 38.7298361,
                                                                "lng": -9.1511788
                                                            },
                                                            "polyline": {
                                                                "points": "olkkFzizv@"
                                                            },
                                                            "html_instructions": "Head on <b>Av. Sidónio Pais</b>",
                                                            "travel_mode": "DRIVING",
                                                            "duration": {
                                                                "text": "1 min",
                                                                "value": 0
                                                            }
                                                        }
                                                    ],
                                                    "via_waypoint": [],
                                                    "start_address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                                    "end_location": {
                                                        "lng": -9.1511788,
                                                        "lat": 38.7298361
                                                    }
                                                },
                                                {
                                                    "end_address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                                                    "traffic_speed_entry": [],
                                                    "distance": {
                                                        "text": "1.4 km",
                                                        "value": 1411
                                                    },
                                                    "steps": [
                                                        {
                                                            "travel_mode": "DRIVING",
                                                            "end_location": {
                                                                "lat": 38.7315755,
                                                                "lng": -9.1527925
                                                            },
                                                            "polyline": {
                                                                "points": "olkkFzizv@sBjBuD~CIFIHGHUX"
                                                            },
                                                            "start_location": {
                                                                "lat": 38.7298361,
                                                                "lng": -9.1511788
                                                            },
                                                            "duration": {
                                                                "text": "1 min",
                                                                "value": 57
                                                            },
                                                            "distance": {
                                                                "text": "0.2 km",
                                                                "value": 239
                                                            },
                                                            "html_instructions": "Head <b>northwest</b> on <b>Av. Sidónio Pais</b> toward <b>Alameda Cardeal Cerejeira</b>"
                                                        },
                                                        {
                                                            "end_location": {
                                                                "lng": -9.152101,
                                                                "lat": 38.732039
                                                            },
                                                            "duration": {
                                                                "value": 32,
                                                                "text": "1 min"
                                                            },
                                                            "travel_mode": "DRIVING",
                                                            "distance": {
                                                                "value": 79,
                                                                "text": "79 m"
                                                            },
                                                            "maneuver": "turn-right",
                                                            "start_location": {
                                                                "lng": -9.1527925,
                                                                "lat": 38.7315755
                                                            },
                                                            "html_instructions": "Turn <b>right</b> onto <b>R. Eng. Canto Resende</b>",
                                                            "polyline": {
                                                                "points": "kwkkF|szv@SUEGACQ[m@kA"
                                                            }
                                                        },
                                                        {
                                                            "distance": {
                                                                "value": 67,
                                                                "text": "67 m"
                                                            },
                                                            "polyline": {
                                                                "points": "gzkkFrozv@CIAECECCAAAA?AAAGGEGGGGKKSOY"
                                                            },
                                                            "end_location": {
                                                                "lat": 38.7324259,
                                                                "lng": -9.1515179
                                                            },
                                                            "travel_mode": "DRIVING",
                                                            "html_instructions": "Continue onto <b>R. Augusto dos Santos</b>",
                                                            "start_location": {
                                                                "lng": -9.152101,
                                                                "lat": 38.732039
                                                            },
                                                            "duration": {
                                                                "value": 25,
                                                                "text": "1 min"
                                                            }
                                                        },
                                                        {
                                                            "end_location": {
                                                                "lng": -9.151345899999999,
                                                                "lat": 38.7322141
                                                            },
                                                            "travel_mode": "DRIVING",
                                                            "maneuver": "turn-right",
                                                            "polyline": {
                                                                "points": "u|kkF~kzv@XSPM"
                                                            },
                                                            "html_instructions": "Turn <b>right</b> onto <b>Largo de São Sebastião da Pedreira</b>",
                                                            "start_location": {
                                                                "lng": -9.1515179,
                                                                "lat": 38.7324259
                                                            },
                                                            "duration": {
                                                                "text": "1 min",
                                                                "value": 8
                                                            },
                                                            "distance": {
                                                                "text": "28 m",
                                                                "value": 28
                                                            }
                                                        },
                                                        {
                                                            "end_location": {
                                                                "lat": 38.730793,
                                                                "lng": -9.1467329
                                                            },
                                                            "maneuver": "turn-left",
                                                            "distance": {
                                                                "text": "0.4 km",
                                                                "value": 445
                                                            },
                                                            "polyline": {
                                                                "points": "i{kkF|jzv@Sc@KU?E?EBMDSDQBI^qAf@mBf@cB`@}A@IDI\\qAVgAFYFSXeAZiABIL_@"
                                                            },
                                                            "duration": {
                                                                "value": 118,
                                                                "text": "2 mins"
                                                            },
                                                            "start_location": {
                                                                "lng": -9.151345899999999,
                                                                "lat": 38.7322141
                                                            },
                                                            "html_instructions": "Turn <b>left</b> onto <b>R. Tomás Ribeiro</b>",
                                                            "travel_mode": "DRIVING"
                                                        },
                                                        {
                                                            "polyline": {
                                                                "points": "mrkkF`nyv@FUsDiBa@UyBkA[O_@SYM?Au@_@YOGEIK"
                                                            },
                                                            "end_location": {
                                                                "lng": -9.145008599999999,
                                                                "lat": 38.733353
                                                            },
                                                            "travel_mode": "DRIVING",
                                                            "distance": {
                                                                "text": "0.3 km",
                                                                "value": 333
                                                            },
                                                            "maneuver": "turn-left",
                                                            "html_instructions": "Turn <b>left</b> onto <b>Av. Fontes Pereira de Melo</b>",
                                                            "start_location": {
                                                                "lng": -9.1467329,
                                                                "lat": 38.730793
                                                            },
                                                            "duration": {
                                                                "text": "1 min",
                                                                "value": 60
                                                            }
                                                        },
                                                        {
                                                            "maneuver": "turn-right",
                                                            "polyline": {
                                                                "points": "mblkFhcyv@@M@MAMAMAKEMEKEIAAA?AA?AAAA??AAAEAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?B"
                                                            },
                                                            "end_location": {
                                                                "lng": -9.144753999999999,
                                                                "lat": 38.7341143
                                                            },
                                                            "start_location": {
                                                                "lat": 38.733353,
                                                                "lng": -9.145008599999999
                                                            },
                                                            "distance": {
                                                                "text": "0.1 km",
                                                                "value": 141
                                                            },
                                                            "html_instructions": "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                                            "duration": {
                                                                "text": "1 min",
                                                                "value": 58
                                                            },
                                                            "travel_mode": "DRIVING"
                                                        },
                                                        {
                                                            "end_location": {
                                                                "lat": 38.7348098,
                                                                "lng": -9.1448403
                                                            },
                                                            "distance": {
                                                                "value": 79,
                                                                "text": "79 m"
                                                            },
                                                            "travel_mode": "DRIVING",
                                                            "polyline": {
                                                                "points": "eglkFtayv@YIA?K?cBZ"
                                                            },
                                                            "start_location": {
                                                                "lat": 38.7341143,
                                                                "lng": -9.144753999999999
                                                            },
                                                            "html_instructions": "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
                                                            "duration": {
                                                                "text": "1 min",
                                                                "value": 28
                                                            },
                                                            "maneuver": "turn-right"
                                                        }
                                                    ],
                                                    "start_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                                    "via_waypoint": [],
                                                    "end_location": {
                                                        "lng": -9.1448403,
                                                        "lat": 38.7348098
                                                    },
                                                    "start_location": {
                                                        "lat": 38.7298361,
                                                        "lng": -9.1511788
                                                    },
                                                    "duration": {
                                                        "value": 386,
                                                        "text": "6 mins"
                                                    }
                                                }
                                            ],
                                            "waypoint_order": [
                                                0
                                            ],
                                            "copyrights": "Map data ©2022 Google",
                                            "warnings": [],
                                            "summary": "Av. Sidónio Pais"
                                        }
                                    ],
                                    "geocoded_waypoints": [
                                        {
                                            "geocoder_status": "OK",
                                            "place_id": "ChIJkzYGeHQzGQ0RNRppJyBSIkw",
                                            "types": [
                                                "street_address"
                                            ]
                                        },
                                        {
                                            "place_id": "ChIJvXvq83MzGQ0RbjOYybAvPE4",
                                            "geocoder_status": "OK",
                                            "types": [
                                                "premise"
                                            ]
                                        },
                                        {
                                            "geocoder_status": "OK",
                                            "types": [
                                                "establishment",
                                                "point_of_interest",
                                                "real_estate_agency"
                                            ],
                                            "place_id": "ChIJJSaMEqAzGQ0RSSzMTk-fmaU"
                                        }
                                    ],
                                    "status": "OK"
                                },
                                "preferences": {
                                    "female_passengers_only": null,
                                    "verified_passengers_only": null
                                },
                                "co2Amount": 0.5798745402740049,
                                "origin": {
                                    "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                    "coordinates": {
                                        "longitude": -9.1514926,
                                        "latitude": 38.7296842
                                    },
                                    "geohash": "eyckrf80fb"
                                },
                                "owner": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                                "skin": null,
                                "status": "completed",
                                "destination": {
                                    "address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                                    "geohash": "eyckrgkywv",
                                    "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                                    "type": "home",
                                    "coordinates": {
                                        "latitude": 38.7348175,
                                        "longitude": -9.1447944
                                    }
                                },
                                "rules": [
                                    "Keep off the radio",
                                    "No smoking",
                                    "No eating or drinking"
                                ],
                                "car": {
                                    "model": "AZERA",
                                    "make": "Hyundai",
                                    "type": "carbon-emitting",
                                    "year": 2013,
                                    "co2_p_km": 0.20599450809023268,
                                    "seat": 3
                                },
                                "createdAt": 1666783821838,
                                "passengersArray": [
                                    {
                                        "id": "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3",
                                        "seat": 1,
                                        "co2Amount": 0.2896282783748671,
                                        "credits": 8.6,
                                        "wallet_id": null,
                                        "status": "picked-up",
                                        "name": "Adisi Karas",
                                        "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_white0320.png",
                                        "location": {
                                            "address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                            "coordinates": {
                                                "latitude": 38.72955322265625,
                                                "longitude": -9.151782318958222
                                            },
                                            "geohash": "eyckrdrzup"
                                        }
                                    },
                                    {
                                        "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_brown536.png",
                                        "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                                        "location": {
                                            "coordinates": {
                                                "latitude": 38.7296842,
                                                "longitude": -9.1514926
                                            },
                                            "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                            "geohash": "eyckrf80fb"
                                        },
                                        "wallet_id": "mikki.thecarbongames.testnet",
                                        "co2Amount": 0.2902462618991378,
                                        "seat": 0,
                                        "name": "Nikki",
                                        "credits": 17.2
                                    }
                                ]
                            },
                            "type": "carpool_passenger"
                        },
                        {
                            "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_brown536.png",
                            "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                            "location": {
                                "coordinates": {
                                    "latitude": 38.7296842,
                                    "longitude": -9.1514926
                                },
                                "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                "geohash": "eyckrf80fb"
                            },
                            "wallet_id": "mikki.thecarbongames.testnet",
                            "co2Amount": 0.2902462618991378,
                            "seat": 0,
                            "name": "Nikki",
                            "credits": 17.2
                        }
                    ]
                },
                "type": "carpool_passenger"
            }
        },
        "createdAt": 1666783821838
    },
    "userId": "user_id",
    "directions": {
        "routes": [
            {
                "bounds": {
                    "northeast": {
                        "lat": 38.7348098,
                        "lng": -9.144371200000002
                    },
                    "southwest": {
                        "lat": 38.7298361,
                        "lng": -9.1527925
                    }
                },
                "overview_polyline": {
                    "points": "olkkFzizv@}H|GGHUXSUGK_AgBMYEG]c@[m@j@a@_@y@?KR}@pCaKd@eB`A{Dt@iCkJ{EoBcAa@UIK@M?[CYSe@OK[IW?OBURQZER?BYIM?cBZ"
                },
                "legs": [
                    {
                        "duration": {
                            "text": "1 min",
                            "value": 0
                        },
                        "start_location": {
                            "lng": -9.1511788,
                            "lat": 38.7298361
                        },
                        "traffic_speed_entry": [],
                        "end_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                        "distance": {
                            "value": 0,
                            "text": "1 m"
                        },
                        "steps": [
                            {
                                "distance": {
                                    "value": 0,
                                    "text": "1 m"
                                },
                                "end_location": {
                                    "lng": -9.1511788,
                                    "lat": 38.7298361
                                },
                                "start_location": {
                                    "lat": 38.7298361,
                                    "lng": -9.1511788
                                },
                                "polyline": {
                                    "points": "olkkFzizv@"
                                },
                                "html_instructions": "Head on <b>Av. Sidónio Pais</b>",
                                "travel_mode": "DRIVING",
                                "duration": {
                                    "text": "1 min",
                                    "value": 0
                                }
                            }
                        ],
                        "via_waypoint": [],
                        "start_address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                        "end_location": {
                            "lng": -9.1511788,
                            "lat": 38.7298361
                        }
                    },
                    {
                        "end_address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                        "traffic_speed_entry": [],
                        "distance": {
                            "text": "1.4 km",
                            "value": 1411
                        },
                        "steps": [
                            {
                                "travel_mode": "DRIVING",
                                "end_location": {
                                    "lat": 38.7315755,
                                    "lng": -9.1527925
                                },
                                "polyline": {
                                    "points": "olkkFzizv@sBjBuD~CIFIHGHUX"
                                },
                                "start_location": {
                                    "lat": 38.7298361,
                                    "lng": -9.1511788
                                },
                                "duration": {
                                    "text": "1 min",
                                    "value": 57
                                },
                                "distance": {
                                    "text": "0.2 km",
                                    "value": 239
                                },
                                "html_instructions": "Head <b>northwest</b> on <b>Av. Sidónio Pais</b> toward <b>Alameda Cardeal Cerejeira</b>"
                            },
                            {
                                "end_location": {
                                    "lng": -9.152101,
                                    "lat": 38.732039
                                },
                                "duration": {
                                    "value": 32,
                                    "text": "1 min"
                                },
                                "travel_mode": "DRIVING",
                                "distance": {
                                    "value": 79,
                                    "text": "79 m"
                                },
                                "maneuver": "turn-right",
                                "start_location": {
                                    "lng": -9.1527925,
                                    "lat": 38.7315755
                                },
                                "html_instructions": "Turn <b>right</b> onto <b>R. Eng. Canto Resende</b>",
                                "polyline": {
                                    "points": "kwkkF|szv@SUEGACQ[m@kA"
                                }
                            },
                            {
                                "distance": {
                                    "value": 67,
                                    "text": "67 m"
                                },
                                "polyline": {
                                    "points": "gzkkFrozv@CIAECECCAAAA?AAAGGEGGGGKKSOY"
                                },
                                "end_location": {
                                    "lat": 38.7324259,
                                    "lng": -9.1515179
                                },
                                "travel_mode": "DRIVING",
                                "html_instructions": "Continue onto <b>R. Augusto dos Santos</b>",
                                "start_location": {
                                    "lng": -9.152101,
                                    "lat": 38.732039
                                },
                                "duration": {
                                    "value": 25,
                                    "text": "1 min"
                                }
                            },
                            {
                                "end_location": {
                                    "lng": -9.151345899999999,
                                    "lat": 38.7322141
                                },
                                "travel_mode": "DRIVING",
                                "maneuver": "turn-right",
                                "polyline": {
                                    "points": "u|kkF~kzv@XSPM"
                                },
                                "html_instructions": "Turn <b>right</b> onto <b>Largo de São Sebastião da Pedreira</b>",
                                "start_location": {
                                    "lng": -9.1515179,
                                    "lat": 38.7324259
                                },
                                "duration": {
                                    "text": "1 min",
                                    "value": 8
                                },
                                "distance": {
                                    "text": "28 m",
                                    "value": 28
                                }
                            },
                            {
                                "end_location": {
                                    "lat": 38.730793,
                                    "lng": -9.1467329
                                },
                                "maneuver": "turn-left",
                                "distance": {
                                    "text": "0.4 km",
                                    "value": 445
                                },
                                "polyline": {
                                    "points": "i{kkF|jzv@Sc@KU?E?EBMDSDQBI^qAf@mBf@cB`@}A@IDI\\qAVgAFYFSXeAZiABIL_@"
                                },
                                "duration": {
                                    "value": 118,
                                    "text": "2 mins"
                                },
                                "start_location": {
                                    "lng": -9.151345899999999,
                                    "lat": 38.7322141
                                },
                                "html_instructions": "Turn <b>left</b> onto <b>R. Tomás Ribeiro</b>",
                                "travel_mode": "DRIVING"
                            },
                            {
                                "polyline": {
                                    "points": "mrkkF`nyv@FUsDiBa@UyBkA[O_@SYM?Au@_@YOGEIK"
                                },
                                "end_location": {
                                    "lng": -9.145008599999999,
                                    "lat": 38.733353
                                },
                                "travel_mode": "DRIVING",
                                "distance": {
                                    "text": "0.3 km",
                                    "value": 333
                                },
                                "maneuver": "turn-left",
                                "html_instructions": "Turn <b>left</b> onto <b>Av. Fontes Pereira de Melo</b>",
                                "start_location": {
                                    "lng": -9.1467329,
                                    "lat": 38.730793
                                },
                                "duration": {
                                    "text": "1 min",
                                    "value": 60
                                }
                            },
                            {
                                "maneuver": "turn-right",
                                "polyline": {
                                    "points": "mblkFhcyv@@M@MAMAMAKEMEKEIAAA?AA?AAAA??AAAEAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?B"
                                },
                                "end_location": {
                                    "lng": -9.144753999999999,
                                    "lat": 38.7341143
                                },
                                "start_location": {
                                    "lat": 38.733353,
                                    "lng": -9.145008599999999
                                },
                                "distance": {
                                    "text": "0.1 km",
                                    "value": 141
                                },
                                "html_instructions": "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                "duration": {
                                    "text": "1 min",
                                    "value": 58
                                },
                                "travel_mode": "DRIVING"
                            },
                            {
                                "end_location": {
                                    "lat": 38.7348098,
                                    "lng": -9.1448403
                                },
                                "distance": {
                                    "value": 79,
                                    "text": "79 m"
                                },
                                "travel_mode": "DRIVING",
                                "polyline": {
                                    "points": "eglkFtayv@YIA?K?cBZ"
                                },
                                "start_location": {
                                    "lat": 38.7341143,
                                    "lng": -9.144753999999999
                                },
                                "html_instructions": "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
                                "duration": {
                                    "text": "1 min",
                                    "value": 28
                                },
                                "maneuver": "turn-right"
                            }
                        ],
                        "start_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                        "via_waypoint": [],
                        "end_location": {
                            "lng": -9.1448403,
                            "lat": 38.7348098
                        },
                        "start_location": {
                            "lat": 38.7298361,
                            "lng": -9.1511788
                        },
                        "duration": {
                            "value": 386,
                            "text": "6 mins"
                        }
                    }
                ],
                "waypoint_order": [
                    0
                ],
                "copyrights": "Map data ©2022 Google",
                "warnings": [],
                "summary": "Av. Sidónio Pais"
            }
        ],
        "geocoded_waypoints": [
            {
                "geocoder_status": "OK",
                "place_id": "ChIJkzYGeHQzGQ0RNRppJyBSIkw",
                "types": [
                    "street_address"
                ]
            },
            {
                "place_id": "ChIJvXvq83MzGQ0RbjOYybAvPE4",
                "geocoder_status": "OK",
                "types": [
                    "premise"
                ]
            },
            {
                "geocoder_status": "OK",
                "types": [
                    "establishment",
                    "point_of_interest",
                    "real_estate_agency"
                ],
                "place_id": "ChIJJSaMEqAzGQ0RSSzMTk-fmaU"
            }
        ],
        "status": "OK"
    },
    "passengers": {
        "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3": {
            "userId": "user_id",
            "name": "Adisi Karas",
            "location": {
                "address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                "coordinates": {
                    "latitude": 38.72955322265625,
                    "longitude": -9.151782318958222
                },
                "geohash": "eyckrdrzup"
            },
            "credits": 8.6,
            "co2Amount": 0.2896282783748671,
            "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_white0320.png",
            "seat": 1,
            "id": "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3",
            "wallet_id": null,
            "status": "picked-up",
            "carpool": {
                "id": "8333216178162",
                "directions": {
                    "routes": [
                        {
                            "bounds": {
                                "northeast": {
                                    "lat": 38.7348098,
                                    "lng": -9.144371200000002
                                },
                                "southwest": {
                                    "lat": 38.7298361,
                                    "lng": -9.1527925
                                }
                            },
                            "overview_polyline": {
                                "points": "olkkFzizv@}H|GGHUXSUGK_AgBMYEG]c@[m@j@a@_@y@?KR}@pCaKd@eB`A{Dt@iCkJ{EoBcAa@UIK@M?[CYSe@OK[IW?OBURQZER?BYIM?cBZ"
                            },
                            "legs": [
                                {
                                    "duration": {
                                        "text": "1 min",
                                        "value": 0
                                    },
                                    "start_location": {
                                        "lng": -9.1511788,
                                        "lat": 38.7298361
                                    },
                                    "traffic_speed_entry": [],
                                    "end_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                    "distance": {
                                        "value": 0,
                                        "text": "1 m"
                                    },
                                    "steps": [
                                        {
                                            "distance": {
                                                "value": 0,
                                                "text": "1 m"
                                            },
                                            "end_location": {
                                                "lng": -9.1511788,
                                                "lat": 38.7298361
                                            },
                                            "start_location": {
                                                "lat": 38.7298361,
                                                "lng": -9.1511788
                                            },
                                            "polyline": {
                                                "points": "olkkFzizv@"
                                            },
                                            "html_instructions": "Head on <b>Av. Sidónio Pais</b>",
                                            "travel_mode": "DRIVING",
                                            "duration": {
                                                "text": "1 min",
                                                "value": 0
                                            }
                                        }
                                    ],
                                    "via_waypoint": [],
                                    "start_address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                    "end_location": {
                                        "lng": -9.1511788,
                                        "lat": 38.7298361
                                    }
                                },
                                {
                                    "end_address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                                    "traffic_speed_entry": [],
                                    "distance": {
                                        "text": "1.4 km",
                                        "value": 1411
                                    },
                                    "steps": [
                                        {
                                            "travel_mode": "DRIVING",
                                            "end_location": {
                                                "lat": 38.7315755,
                                                "lng": -9.1527925
                                            },
                                            "polyline": {
                                                "points": "olkkFzizv@sBjBuD~CIFIHGHUX"
                                            },
                                            "start_location": {
                                                "lat": 38.7298361,
                                                "lng": -9.1511788
                                            },
                                            "duration": {
                                                "text": "1 min",
                                                "value": 57
                                            },
                                            "distance": {
                                                "text": "0.2 km",
                                                "value": 239
                                            },
                                            "html_instructions": "Head <b>northwest</b> on <b>Av. Sidónio Pais</b> toward <b>Alameda Cardeal Cerejeira</b>"
                                        },
                                        {
                                            "end_location": {
                                                "lng": -9.152101,
                                                "lat": 38.732039
                                            },
                                            "duration": {
                                                "value": 32,
                                                "text": "1 min"
                                            },
                                            "travel_mode": "DRIVING",
                                            "distance": {
                                                "value": 79,
                                                "text": "79 m"
                                            },
                                            "maneuver": "turn-right",
                                            "start_location": {
                                                "lng": -9.1527925,
                                                "lat": 38.7315755
                                            },
                                            "html_instructions": "Turn <b>right</b> onto <b>R. Eng. Canto Resende</b>",
                                            "polyline": {
                                                "points": "kwkkF|szv@SUEGACQ[m@kA"
                                            }
                                        },
                                        {
                                            "distance": {
                                                "value": 67,
                                                "text": "67 m"
                                            },
                                            "polyline": {
                                                "points": "gzkkFrozv@CIAECECCAAAA?AAAGGEGGGGKKSOY"
                                            },
                                            "end_location": {
                                                "lat": 38.7324259,
                                                "lng": -9.1515179
                                            },
                                            "travel_mode": "DRIVING",
                                            "html_instructions": "Continue onto <b>R. Augusto dos Santos</b>",
                                            "start_location": {
                                                "lng": -9.152101,
                                                "lat": 38.732039
                                            },
                                            "duration": {
                                                "value": 25,
                                                "text": "1 min"
                                            }
                                        },
                                        {
                                            "end_location": {
                                                "lng": -9.151345899999999,
                                                "lat": 38.7322141
                                            },
                                            "travel_mode": "DRIVING",
                                            "maneuver": "turn-right",
                                            "polyline": {
                                                "points": "u|kkF~kzv@XSPM"
                                            },
                                            "html_instructions": "Turn <b>right</b> onto <b>Largo de São Sebastião da Pedreira</b>",
                                            "start_location": {
                                                "lng": -9.1515179,
                                                "lat": 38.7324259
                                            },
                                            "duration": {
                                                "text": "1 min",
                                                "value": 8
                                            },
                                            "distance": {
                                                "text": "28 m",
                                                "value": 28
                                            }
                                        },
                                        {
                                            "end_location": {
                                                "lat": 38.730793,
                                                "lng": -9.1467329
                                            },
                                            "maneuver": "turn-left",
                                            "distance": {
                                                "text": "0.4 km",
                                                "value": 445
                                            },
                                            "polyline": {
                                                "points": "i{kkF|jzv@Sc@KU?E?EBMDSDQBI^qAf@mBf@cB`@}A@IDI\\qAVgAFYFSXeAZiABIL_@"
                                            },
                                            "duration": {
                                                "value": 118,
                                                "text": "2 mins"
                                            },
                                            "start_location": {
                                                "lng": -9.151345899999999,
                                                "lat": 38.7322141
                                            },
                                            "html_instructions": "Turn <b>left</b> onto <b>R. Tomás Ribeiro</b>",
                                            "travel_mode": "DRIVING"
                                        },
                                        {
                                            "polyline": {
                                                "points": "mrkkF`nyv@FUsDiBa@UyBkA[O_@SYM?Au@_@YOGEIK"
                                            },
                                            "end_location": {
                                                "lng": -9.145008599999999,
                                                "lat": 38.733353
                                            },
                                            "travel_mode": "DRIVING",
                                            "distance": {
                                                "text": "0.3 km",
                                                "value": 333
                                            },
                                            "maneuver": "turn-left",
                                            "html_instructions": "Turn <b>left</b> onto <b>Av. Fontes Pereira de Melo</b>",
                                            "start_location": {
                                                "lng": -9.1467329,
                                                "lat": 38.730793
                                            },
                                            "duration": {
                                                "text": "1 min",
                                                "value": 60
                                            }
                                        },
                                        {
                                            "maneuver": "turn-right",
                                            "polyline": {
                                                "points": "mblkFhcyv@@M@MAMAMAKEMEKEIAAA?AA?AAAA??AAAEAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?B"
                                            },
                                            "end_location": {
                                                "lng": -9.144753999999999,
                                                "lat": 38.7341143
                                            },
                                            "start_location": {
                                                "lat": 38.733353,
                                                "lng": -9.145008599999999
                                            },
                                            "distance": {
                                                "text": "0.1 km",
                                                "value": 141
                                            },
                                            "html_instructions": "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                            "duration": {
                                                "text": "1 min",
                                                "value": 58
                                            },
                                            "travel_mode": "DRIVING"
                                        },
                                        {
                                            "end_location": {
                                                "lat": 38.7348098,
                                                "lng": -9.1448403
                                            },
                                            "distance": {
                                                "value": 79,
                                                "text": "79 m"
                                            },
                                            "travel_mode": "DRIVING",
                                            "polyline": {
                                                "points": "eglkFtayv@YIA?K?cBZ"
                                            },
                                            "start_location": {
                                                "lat": 38.7341143,
                                                "lng": -9.144753999999999
                                            },
                                            "html_instructions": "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
                                            "duration": {
                                                "text": "1 min",
                                                "value": 28
                                            },
                                            "maneuver": "turn-right"
                                        }
                                    ],
                                    "start_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                    "via_waypoint": [],
                                    "end_location": {
                                        "lng": -9.1448403,
                                        "lat": 38.7348098
                                    },
                                    "start_location": {
                                        "lat": 38.7298361,
                                        "lng": -9.1511788
                                    },
                                    "duration": {
                                        "value": 386,
                                        "text": "6 mins"
                                    }
                                }
                            ],
                            "waypoint_order": [
                                0
                            ],
                            "copyrights": "Map data ©2022 Google",
                            "warnings": [],
                            "summary": "Av. Sidónio Pais"
                        }
                    ],
                    "geocoded_waypoints": [
                        {
                            "geocoder_status": "OK",
                            "place_id": "ChIJkzYGeHQzGQ0RNRppJyBSIkw",
                            "types": [
                                "street_address"
                            ]
                        },
                        {
                            "place_id": "ChIJvXvq83MzGQ0RbjOYybAvPE4",
                            "geocoder_status": "OK",
                            "types": [
                                "premise"
                            ]
                        },
                        {
                            "geocoder_status": "OK",
                            "types": [
                                "establishment",
                                "point_of_interest",
                                "real_estate_agency"
                            ],
                            "place_id": "ChIJJSaMEqAzGQ0RSSzMTk-fmaU"
                        }
                    ],
                    "status": "OK"
                },
                "preferences": {
                    "female_passengers_only": null,
                    "verified_passengers_only": null
                },
                "co2Amount": 0.5798745402740049,
                "origin": {
                    "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                    "coordinates": {
                        "longitude": -9.1514926,
                        "latitude": 38.7296842
                    },
                    "geohash": "eyckrf80fb"
                },
                "owner": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                "skin": null,
                "status": "completed",
                "destination": {
                    "address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                    "geohash": "eyckrgkywv",
                    "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                    "type": "home",
                    "coordinates": {
                        "latitude": 38.7348175,
                        "longitude": -9.1447944
                    }
                },
                "rules": [
                    "Keep off the radio",
                    "No smoking",
                    "No eating or drinking"
                ],
                "car": {
                    "model": "AZERA",
                    "make": "Hyundai",
                    "type": "carbon-emitting",
                    "year": 2013,
                    "co2_p_km": 0.20599450809023268,
                    "seat": 3
                },
                "createdAt": 1666783821838,
                "passengersArray": [
                    {
                        "id": "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3",
                        "seat": 1,
                        "co2Amount": 0.2896282783748671,
                        "credits": 8.6,
                        "wallet_id": null,
                        "status": "picked-up",
                        "name": "Adisi Karas",
                        "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_white0320.png",
                        "location": {
                            "address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                            "coordinates": {
                                "latitude": 38.72955322265625,
                                "longitude": -9.151782318958222
                            },
                            "geohash": "eyckrdrzup"
                        }
                    },
                    {
                        "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_brown536.png",
                        "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                        "location": {
                            "coordinates": {
                                "latitude": 38.7296842,
                                "longitude": -9.1514926
                            },
                            "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                            "geohash": "eyckrf80fb"
                        },
                        "wallet_id": "mikki.thecarbongames.testnet",
                        "co2Amount": 0.2902462618991378,
                        "seat": 0,
                        "name": "Nikki",
                        "credits": 17.2
                    }
                ]
            },
            "type": "carpool_passenger"
        },
        "79xk72qzvFQvUtWH4Ed2gdLq8Yf1": {
            "userId": "user_id",
            "name": "Nikki",
            "location": {
                "coordinates": {
                    "latitude": 38.7296842,
                    "longitude": -9.1514926
                },
                "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                "geohash": "eyckrf80fb"
            },
            "credits": 17.2,
            "co2Amount": 0.2902462618991378,
            "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_brown536.png",
            "seat": 0,
            "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
            "wallet_id": "mikki.thecarbongames.testnet",
            "carpool": {
                "id": "8333216178162",
                "directions": {
                    "routes": [
                        {
                            "bounds": {
                                "northeast": {
                                    "lat": 38.7348098,
                                    "lng": -9.144371200000002
                                },
                                "southwest": {
                                    "lat": 38.7298361,
                                    "lng": -9.1527925
                                }
                            },
                            "overview_polyline": {
                                "points": "olkkFzizv@}H|GGHUXSUGK_AgBMYEG]c@[m@j@a@_@y@?KR}@pCaKd@eB`A{Dt@iCkJ{EoBcAa@UIK@M?[CYSe@OK[IW?OBURQZER?BYIM?cBZ"
                            },
                            "legs": [
                                {
                                    "duration": {
                                        "text": "1 min",
                                        "value": 0
                                    },
                                    "start_location": {
                                        "lng": -9.1511788,
                                        "lat": 38.7298361
                                    },
                                    "traffic_speed_entry": [],
                                    "end_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                    "distance": {
                                        "value": 0,
                                        "text": "1 m"
                                    },
                                    "steps": [
                                        {
                                            "distance": {
                                                "value": 0,
                                                "text": "1 m"
                                            },
                                            "end_location": {
                                                "lng": -9.1511788,
                                                "lat": 38.7298361
                                            },
                                            "start_location": {
                                                "lat": 38.7298361,
                                                "lng": -9.1511788
                                            },
                                            "polyline": {
                                                "points": "olkkFzizv@"
                                            },
                                            "html_instructions": "Head on <b>Av. Sidónio Pais</b>",
                                            "travel_mode": "DRIVING",
                                            "duration": {
                                                "text": "1 min",
                                                "value": 0
                                            }
                                        }
                                    ],
                                    "via_waypoint": [],
                                    "start_address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                    "end_location": {
                                        "lng": -9.1511788,
                                        "lat": 38.7298361
                                    }
                                },
                                {
                                    "end_address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                                    "traffic_speed_entry": [],
                                    "distance": {
                                        "text": "1.4 km",
                                        "value": 1411
                                    },
                                    "steps": [
                                        {
                                            "travel_mode": "DRIVING",
                                            "end_location": {
                                                "lat": 38.7315755,
                                                "lng": -9.1527925
                                            },
                                            "polyline": {
                                                "points": "olkkFzizv@sBjBuD~CIFIHGHUX"
                                            },
                                            "start_location": {
                                                "lat": 38.7298361,
                                                "lng": -9.1511788
                                            },
                                            "duration": {
                                                "text": "1 min",
                                                "value": 57
                                            },
                                            "distance": {
                                                "text": "0.2 km",
                                                "value": 239
                                            },
                                            "html_instructions": "Head <b>northwest</b> on <b>Av. Sidónio Pais</b> toward <b>Alameda Cardeal Cerejeira</b>"
                                        },
                                        {
                                            "end_location": {
                                                "lng": -9.152101,
                                                "lat": 38.732039
                                            },
                                            "duration": {
                                                "value": 32,
                                                "text": "1 min"
                                            },
                                            "travel_mode": "DRIVING",
                                            "distance": {
                                                "value": 79,
                                                "text": "79 m"
                                            },
                                            "maneuver": "turn-right",
                                            "start_location": {
                                                "lng": -9.1527925,
                                                "lat": 38.7315755
                                            },
                                            "html_instructions": "Turn <b>right</b> onto <b>R. Eng. Canto Resende</b>",
                                            "polyline": {
                                                "points": "kwkkF|szv@SUEGACQ[m@kA"
                                            }
                                        },
                                        {
                                            "distance": {
                                                "value": 67,
                                                "text": "67 m"
                                            },
                                            "polyline": {
                                                "points": "gzkkFrozv@CIAECECCAAAA?AAAGGEGGGGKKSOY"
                                            },
                                            "end_location": {
                                                "lat": 38.7324259,
                                                "lng": -9.1515179
                                            },
                                            "travel_mode": "DRIVING",
                                            "html_instructions": "Continue onto <b>R. Augusto dos Santos</b>",
                                            "start_location": {
                                                "lng": -9.152101,
                                                "lat": 38.732039
                                            },
                                            "duration": {
                                                "value": 25,
                                                "text": "1 min"
                                            }
                                        },
                                        {
                                            "end_location": {
                                                "lng": -9.151345899999999,
                                                "lat": 38.7322141
                                            },
                                            "travel_mode": "DRIVING",
                                            "maneuver": "turn-right",
                                            "polyline": {
                                                "points": "u|kkF~kzv@XSPM"
                                            },
                                            "html_instructions": "Turn <b>right</b> onto <b>Largo de São Sebastião da Pedreira</b>",
                                            "start_location": {
                                                "lng": -9.1515179,
                                                "lat": 38.7324259
                                            },
                                            "duration": {
                                                "text": "1 min",
                                                "value": 8
                                            },
                                            "distance": {
                                                "text": "28 m",
                                                "value": 28
                                            }
                                        },
                                        {
                                            "end_location": {
                                                "lat": 38.730793,
                                                "lng": -9.1467329
                                            },
                                            "maneuver": "turn-left",
                                            "distance": {
                                                "text": "0.4 km",
                                                "value": 445
                                            },
                                            "polyline": {
                                                "points": "i{kkF|jzv@Sc@KU?E?EBMDSDQBI^qAf@mBf@cB`@}A@IDI\\qAVgAFYFSXeAZiABIL_@"
                                            },
                                            "duration": {
                                                "value": 118,
                                                "text": "2 mins"
                                            },
                                            "start_location": {
                                                "lng": -9.151345899999999,
                                                "lat": 38.7322141
                                            },
                                            "html_instructions": "Turn <b>left</b> onto <b>R. Tomás Ribeiro</b>",
                                            "travel_mode": "DRIVING"
                                        },
                                        {
                                            "polyline": {
                                                "points": "mrkkF`nyv@FUsDiBa@UyBkA[O_@SYM?Au@_@YOGEIK"
                                            },
                                            "end_location": {
                                                "lng": -9.145008599999999,
                                                "lat": 38.733353
                                            },
                                            "travel_mode": "DRIVING",
                                            "distance": {
                                                "text": "0.3 km",
                                                "value": 333
                                            },
                                            "maneuver": "turn-left",
                                            "html_instructions": "Turn <b>left</b> onto <b>Av. Fontes Pereira de Melo</b>",
                                            "start_location": {
                                                "lng": -9.1467329,
                                                "lat": 38.730793
                                            },
                                            "duration": {
                                                "text": "1 min",
                                                "value": 60
                                            }
                                        },
                                        {
                                            "maneuver": "turn-right",
                                            "polyline": {
                                                "points": "mblkFhcyv@@M@MAMAMAKEMEKEIAAA?AA?AAAA??AAAEAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?B"
                                            },
                                            "end_location": {
                                                "lng": -9.144753999999999,
                                                "lat": 38.7341143
                                            },
                                            "start_location": {
                                                "lat": 38.733353,
                                                "lng": -9.145008599999999
                                            },
                                            "distance": {
                                                "text": "0.1 km",
                                                "value": 141
                                            },
                                            "html_instructions": "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                            "duration": {
                                                "text": "1 min",
                                                "value": 58
                                            },
                                            "travel_mode": "DRIVING"
                                        },
                                        {
                                            "end_location": {
                                                "lat": 38.7348098,
                                                "lng": -9.1448403
                                            },
                                            "distance": {
                                                "value": 79,
                                                "text": "79 m"
                                            },
                                            "travel_mode": "DRIVING",
                                            "polyline": {
                                                "points": "eglkFtayv@YIA?K?cBZ"
                                            },
                                            "start_location": {
                                                "lat": 38.7341143,
                                                "lng": -9.144753999999999
                                            },
                                            "html_instructions": "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
                                            "duration": {
                                                "text": "1 min",
                                                "value": 28
                                            },
                                            "maneuver": "turn-right"
                                        }
                                    ],
                                    "start_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                    "via_waypoint": [],
                                    "end_location": {
                                        "lng": -9.1448403,
                                        "lat": 38.7348098
                                    },
                                    "start_location": {
                                        "lat": 38.7298361,
                                        "lng": -9.1511788
                                    },
                                    "duration": {
                                        "value": 386,
                                        "text": "6 mins"
                                    }
                                }
                            ],
                            "waypoint_order": [
                                0
                            ],
                            "copyrights": "Map data ©2022 Google",
                            "warnings": [],
                            "summary": "Av. Sidónio Pais"
                        }
                    ],
                    "geocoded_waypoints": [
                        {
                            "geocoder_status": "OK",
                            "place_id": "ChIJkzYGeHQzGQ0RNRppJyBSIkw",
                            "types": [
                                "street_address"
                            ]
                        },
                        {
                            "place_id": "ChIJvXvq83MzGQ0RbjOYybAvPE4",
                            "geocoder_status": "OK",
                            "types": [
                                "premise"
                            ]
                        },
                        {
                            "geocoder_status": "OK",
                            "types": [
                                "establishment",
                                "point_of_interest",
                                "real_estate_agency"
                            ],
                            "place_id": "ChIJJSaMEqAzGQ0RSSzMTk-fmaU"
                        }
                    ],
                    "status": "OK"
                },
                "preferences": {
                    "female_passengers_only": null,
                    "verified_passengers_only": null
                },
                "co2Amount": 0.5798745402740049,
                "origin": {
                    "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                    "coordinates": {
                        "longitude": -9.1514926,
                        "latitude": 38.7296842
                    },
                    "geohash": "eyckrf80fb"
                },
                "owner": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                "skin": null,
                "status": "completed",
                "destination": {
                    "address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                    "geohash": "eyckrgkywv",
                    "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                    "type": "home",
                    "coordinates": {
                        "latitude": 38.7348175,
                        "longitude": -9.1447944
                    }
                },
                "rules": [
                    "Keep off the radio",
                    "No smoking",
                    "No eating or drinking"
                ],
                "car": {
                    "model": "AZERA",
                    "make": "Hyundai",
                    "type": "carbon-emitting",
                    "year": 2013,
                    "co2_p_km": 0.20599450809023268,
                    "seat": 3
                },
                "createdAt": 1666783821838,
                "passengersArray": [
                    {
                        "userId": "user_id",
                        "name": "Adisi Karas",
                        "location": {
                            "address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                            "coordinates": {
                                "latitude": 38.72955322265625,
                                "longitude": -9.151782318958222
                            },
                            "geohash": "eyckrdrzup"
                        },
                        "credits": 8.6,
                        "co2Amount": 0.2896282783748671,
                        "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_white0320.png",
                        "seat": 1,
                        "id": "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3",
                        "wallet_id": null,
                        "status": "picked-up",
                        "carpool": {
                            "id": "8333216178162",
                            "directions": {
                                "routes": [
                                    {
                                        "bounds": {
                                            "northeast": {
                                                "lat": 38.7348098,
                                                "lng": -9.144371200000002
                                            },
                                            "southwest": {
                                                "lat": 38.7298361,
                                                "lng": -9.1527925
                                            }
                                        },
                                        "overview_polyline": {
                                            "points": "olkkFzizv@}H|GGHUXSUGK_AgBMYEG]c@[m@j@a@_@y@?KR}@pCaKd@eB`A{Dt@iCkJ{EoBcAa@UIK@M?[CYSe@OK[IW?OBURQZER?BYIM?cBZ"
                                        },
                                        "legs": [
                                            {
                                                "duration": {
                                                    "text": "1 min",
                                                    "value": 0
                                                },
                                                "start_location": {
                                                    "lng": -9.1511788,
                                                    "lat": 38.7298361
                                                },
                                                "traffic_speed_entry": [],
                                                "end_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                                "distance": {
                                                    "value": 0,
                                                    "text": "1 m"
                                                },
                                                "steps": [
                                                    {
                                                        "distance": {
                                                            "value": 0,
                                                            "text": "1 m"
                                                        },
                                                        "end_location": {
                                                            "lng": -9.1511788,
                                                            "lat": 38.7298361
                                                        },
                                                        "start_location": {
                                                            "lat": 38.7298361,
                                                            "lng": -9.1511788
                                                        },
                                                        "polyline": {
                                                            "points": "olkkFzizv@"
                                                        },
                                                        "html_instructions": "Head on <b>Av. Sidónio Pais</b>",
                                                        "travel_mode": "DRIVING",
                                                        "duration": {
                                                            "text": "1 min",
                                                            "value": 0
                                                        }
                                                    }
                                                ],
                                                "via_waypoint": [],
                                                "start_address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                                "end_location": {
                                                    "lng": -9.1511788,
                                                    "lat": 38.7298361
                                                }
                                            },
                                            {
                                                "end_address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                                                "traffic_speed_entry": [],
                                                "distance": {
                                                    "text": "1.4 km",
                                                    "value": 1411
                                                },
                                                "steps": [
                                                    {
                                                        "travel_mode": "DRIVING",
                                                        "end_location": {
                                                            "lat": 38.7315755,
                                                            "lng": -9.1527925
                                                        },
                                                        "polyline": {
                                                            "points": "olkkFzizv@sBjBuD~CIFIHGHUX"
                                                        },
                                                        "start_location": {
                                                            "lat": 38.7298361,
                                                            "lng": -9.1511788
                                                        },
                                                        "duration": {
                                                            "text": "1 min",
                                                            "value": 57
                                                        },
                                                        "distance": {
                                                            "text": "0.2 km",
                                                            "value": 239
                                                        },
                                                        "html_instructions": "Head <b>northwest</b> on <b>Av. Sidónio Pais</b> toward <b>Alameda Cardeal Cerejeira</b>"
                                                    },
                                                    {
                                                        "end_location": {
                                                            "lng": -9.152101,
                                                            "lat": 38.732039
                                                        },
                                                        "duration": {
                                                            "value": 32,
                                                            "text": "1 min"
                                                        },
                                                        "travel_mode": "DRIVING",
                                                        "distance": {
                                                            "value": 79,
                                                            "text": "79 m"
                                                        },
                                                        "maneuver": "turn-right",
                                                        "start_location": {
                                                            "lng": -9.1527925,
                                                            "lat": 38.7315755
                                                        },
                                                        "html_instructions": "Turn <b>right</b> onto <b>R. Eng. Canto Resende</b>",
                                                        "polyline": {
                                                            "points": "kwkkF|szv@SUEGACQ[m@kA"
                                                        }
                                                    },
                                                    {
                                                        "distance": {
                                                            "value": 67,
                                                            "text": "67 m"
                                                        },
                                                        "polyline": {
                                                            "points": "gzkkFrozv@CIAECECCAAAA?AAAGGEGGGGKKSOY"
                                                        },
                                                        "end_location": {
                                                            "lat": 38.7324259,
                                                            "lng": -9.1515179
                                                        },
                                                        "travel_mode": "DRIVING",
                                                        "html_instructions": "Continue onto <b>R. Augusto dos Santos</b>",
                                                        "start_location": {
                                                            "lng": -9.152101,
                                                            "lat": 38.732039
                                                        },
                                                        "duration": {
                                                            "value": 25,
                                                            "text": "1 min"
                                                        }
                                                    },
                                                    {
                                                        "end_location": {
                                                            "lng": -9.151345899999999,
                                                            "lat": 38.7322141
                                                        },
                                                        "travel_mode": "DRIVING",
                                                        "maneuver": "turn-right",
                                                        "polyline": {
                                                            "points": "u|kkF~kzv@XSPM"
                                                        },
                                                        "html_instructions": "Turn <b>right</b> onto <b>Largo de São Sebastião da Pedreira</b>",
                                                        "start_location": {
                                                            "lng": -9.1515179,
                                                            "lat": 38.7324259
                                                        },
                                                        "duration": {
                                                            "text": "1 min",
                                                            "value": 8
                                                        },
                                                        "distance": {
                                                            "text": "28 m",
                                                            "value": 28
                                                        }
                                                    },
                                                    {
                                                        "end_location": {
                                                            "lat": 38.730793,
                                                            "lng": -9.1467329
                                                        },
                                                        "maneuver": "turn-left",
                                                        "distance": {
                                                            "text": "0.4 km",
                                                            "value": 445
                                                        },
                                                        "polyline": {
                                                            "points": "i{kkF|jzv@Sc@KU?E?EBMDSDQBI^qAf@mBf@cB`@}A@IDI\\qAVgAFYFSXeAZiABIL_@"
                                                        },
                                                        "duration": {
                                                            "value": 118,
                                                            "text": "2 mins"
                                                        },
                                                        "start_location": {
                                                            "lng": -9.151345899999999,
                                                            "lat": 38.7322141
                                                        },
                                                        "html_instructions": "Turn <b>left</b> onto <b>R. Tomás Ribeiro</b>",
                                                        "travel_mode": "DRIVING"
                                                    },
                                                    {
                                                        "polyline": {
                                                            "points": "mrkkF`nyv@FUsDiBa@UyBkA[O_@SYM?Au@_@YOGEIK"
                                                        },
                                                        "end_location": {
                                                            "lng": -9.145008599999999,
                                                            "lat": 38.733353
                                                        },
                                                        "travel_mode": "DRIVING",
                                                        "distance": {
                                                            "text": "0.3 km",
                                                            "value": 333
                                                        },
                                                        "maneuver": "turn-left",
                                                        "html_instructions": "Turn <b>left</b> onto <b>Av. Fontes Pereira de Melo</b>",
                                                        "start_location": {
                                                            "lng": -9.1467329,
                                                            "lat": 38.730793
                                                        },
                                                        "duration": {
                                                            "text": "1 min",
                                                            "value": 60
                                                        }
                                                    },
                                                    {
                                                        "maneuver": "turn-right",
                                                        "polyline": {
                                                            "points": "mblkFhcyv@@M@MAMAMAKEMEKEIAAA?AA?AAAA??AAAEAGCEAEAGAE?G?E?C?A?A@A?A?C?A@A?EBEDEBCDEDCFCDCFAB?DADAB?B"
                                                        },
                                                        "end_location": {
                                                            "lng": -9.144753999999999,
                                                            "lat": 38.7341143
                                                        },
                                                        "start_location": {
                                                            "lat": 38.733353,
                                                            "lng": -9.145008599999999
                                                        },
                                                        "distance": {
                                                            "text": "0.1 km",
                                                            "value": 141
                                                        },
                                                        "html_instructions": "Turn <b>right</b> onto <b>Praça Duque de Saldanha</b>",
                                                        "duration": {
                                                            "text": "1 min",
                                                            "value": 58
                                                        },
                                                        "travel_mode": "DRIVING"
                                                    },
                                                    {
                                                        "end_location": {
                                                            "lat": 38.7348098,
                                                            "lng": -9.1448403
                                                        },
                                                        "distance": {
                                                            "value": 79,
                                                            "text": "79 m"
                                                        },
                                                        "travel_mode": "DRIVING",
                                                        "polyline": {
                                                            "points": "eglkFtayv@YIA?K?cBZ"
                                                        },
                                                        "start_location": {
                                                            "lat": 38.7341143,
                                                            "lng": -9.144753999999999
                                                        },
                                                        "html_instructions": "Turn <b>right</b> onto <b>Av. da República</b>/<wbr/><b>Av. República</b><div style=\"font-size:0.9em\">Destination will be on the right</div>",
                                                        "duration": {
                                                            "text": "1 min",
                                                            "value": 28
                                                        },
                                                        "maneuver": "turn-right"
                                                    }
                                                ],
                                                "start_address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                                "via_waypoint": [],
                                                "end_location": {
                                                    "lng": -9.1448403,
                                                    "lat": 38.7348098
                                                },
                                                "start_location": {
                                                    "lat": 38.7298361,
                                                    "lng": -9.1511788
                                                },
                                                "duration": {
                                                    "value": 386,
                                                    "text": "6 mins"
                                                }
                                            }
                                        ],
                                        "waypoint_order": [
                                            0
                                        ],
                                        "copyrights": "Map data ©2022 Google",
                                        "warnings": [],
                                        "summary": "Av. Sidónio Pais"
                                    }
                                ],
                                "geocoded_waypoints": [
                                    {
                                        "geocoder_status": "OK",
                                        "place_id": "ChIJkzYGeHQzGQ0RNRppJyBSIkw",
                                        "types": [
                                            "street_address"
                                        ]
                                    },
                                    {
                                        "place_id": "ChIJvXvq83MzGQ0RbjOYybAvPE4",
                                        "geocoder_status": "OK",
                                        "types": [
                                            "premise"
                                        ]
                                    },
                                    {
                                        "geocoder_status": "OK",
                                        "types": [
                                            "establishment",
                                            "point_of_interest",
                                            "real_estate_agency"
                                        ],
                                        "place_id": "ChIJJSaMEqAzGQ0RSSzMTk-fmaU"
                                    }
                                ],
                                "status": "OK"
                            },
                            "preferences": {
                                "female_passengers_only": null,
                                "verified_passengers_only": null
                            },
                            "co2Amount": 0.5798745402740049,
                            "origin": {
                                "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                "coordinates": {
                                    "longitude": -9.1514926,
                                    "latitude": 38.7296842
                                },
                                "geohash": "eyckrf80fb"
                            },
                            "owner": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                            "skin": null,
                            "status": "completed",
                            "destination": {
                                "address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
                                "geohash": "eyckrgkywv",
                                "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                                "type": "home",
                                "coordinates": {
                                    "latitude": 38.7348175,
                                    "longitude": -9.1447944
                                }
                            },
                            "rules": [
                                "Keep off the radio",
                                "No smoking",
                                "No eating or drinking"
                            ],
                            "car": {
                                "model": "AZERA",
                                "make": "Hyundai",
                                "type": "carbon-emitting",
                                "year": 2013,
                                "co2_p_km": 0.20599450809023268,
                                "seat": 3
                            },
                            "createdAt": 1666783821838,
                            "passengersArray": [
                                {
                                    "id": "u11zNkCcq6g6xkzKJ8PtTOIJaHJ3",
                                    "seat": 1,
                                    "co2Amount": 0.2896282783748671,
                                    "credits": 8.6,
                                    "wallet_id": null,
                                    "status": "picked-up",
                                    "name": "Adisi Karas",
                                    "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_white0320.png",
                                    "location": {
                                        "address": "Pavilhão Carlos Lopes, 1070-051 Lisboa, Portugal",
                                        "coordinates": {
                                            "latitude": 38.72955322265625,
                                            "longitude": -9.151782318958222
                                        },
                                        "geohash": "eyckrdrzup"
                                    }
                                },
                                {
                                    "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_brown536.png",
                                    "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                                    "location": {
                                        "coordinates": {
                                            "latitude": 38.7296842,
                                            "longitude": -9.1514926
                                        },
                                        "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                                        "geohash": "eyckrf80fb"
                                    },
                                    "wallet_id": "mikki.thecarbongames.testnet",
                                    "co2Amount": 0.2902462618991378,
                                    "seat": 0,
                                    "name": "Nikki",
                                    "credits": 17.2
                                }
                            ]
                        },
                        "type": "carpool_passenger"
                    },
                    {
                        "avatar": "https://storage.googleapis.com/thecarbongames.appspot.com/avatars%2FAvatarz_brown536.png",
                        "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
                        "location": {
                            "coordinates": {
                                "latitude": 38.7296842,
                                "longitude": -9.1514926
                            },
                            "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
                            "geohash": "eyckrf80fb"
                        },
                        "wallet_id": "mikki.thecarbongames.testnet",
                        "co2Amount": 0.2902462618991378,
                        "seat": 0,
                        "name": "Nikki",
                        "credits": 17.2
                    }
                ]
            },
            "type": "carpool_passenger"
        }
    },
    "skin": null,
    "car": {
        "model": "AZERA",
        "make": "Hyundai",
        "type": "carbon-emitting",
        "year": 2013,
        "co2_p_km": 0.20599450809023268,
        "seat": 3
    },
    "owner": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
    "status": "completed",
    "id": "8333216178162",
    "origin": {
        "address": "Av. Sidónio Pais 18, 1050-016 Lisboa, Portugal",
        "coordinates": {
            "longitude": -9.1514926,
            "latitude": 38.7296842
        },
        "geohash": "eyckrf80fb"
    },
    "destination": {
        "address": "Av. da República nº 6 1º Esq, 1050-191 Lisboa, Portugal",
        "geohash": "eyckrgkywv",
        "id": "79xk72qzvFQvUtWH4Ed2gdLq8Yf1",
        "type": "home",
        "coordinates": {
            "latitude": 38.7348175,
            "longitude": -9.1447944
        }
    },
    "credits": 0,
    "createdAt": 1666783821838,
    "co2Amount": 0.5798745402740049,
    "autoUpdateTime": 0,
    "ownerRating": "",
    "type": "carpool",
    "rules": [
        "Keep off the radio",
        "No smoking",
        "No eating or drinking"
    ],
    "preferences": {
        "female_passengers_only": null,
        "verified_passengers_only": null
    }
}, 'u11zNkCcq6g6xkzKJ8PtTOIJaHJ3')