import Images from "../assets/Images"
import Constants from "../common/Constants"
import CustomButton from "../components/common/CustomButton"
import ImageIcon from "../components/common/ImageIcon"
import colors from '../assets/colors'
import Dropdown from "../components/common/Dropdown"
import DropdownOption from "../types/Common"
import ProjectItem from "../components/common/ProjectItem"
import { useNavigate } from "react-router-dom"
import Tabs from "../components/common/Tabs"
import { useMemo, useState } from "react"
import CustomCheckbox from "../components/common/CustomCheckbox"
import NFTTokenItem from "../components/common/NFTTokenItem"
import { useNearWallet } from "../hooks/useNearWallet"
import { useAuth } from "../providers/AuthProvider"

const Marketplace = () => {

    const navigate = useNavigate()

    //states
    const [marketplaceMode, setMarketPlaceMode] = useState<'donate' | 'nft-marketplace'>('donate')

    //default-hooks

    //higher-hooks

    //sub-hooks

    return (
        <div className="flex-1" >
            <div
                className="p-12 overflow-y-auto"
                style={{ maxHeight: `calc(100vh - ${Constants.header_height}px)` }} >

                <div className="flex mb-12 items-center gap-4" >
                    <p className="text-2xl font-bold" >Marketplace</p>

                    <div style={{ flex: 1 }} />

                    <Tabs
                        style={{ minWidth: 300 }}
                        tabStyle={{ padding: '9px 16px' }}
                        hidable={false}
                        value={marketplaceMode}
                        options={carpoolsTypes}
                        onSelect={(v) => setMarketPlaceMode(v as any)} />

                    <CustomButton onClick={() => navigate('donation-history')} icon="donation" label="Donation History" />
                </div>



                {marketplaceMode === 'donate' && <DonateModeView />}
                {marketplaceMode === 'nft-marketplace' && <NFTMarketPlaceView />}

            </div>

        </div>

    )
}

export default Marketplace

const DonateModeView = () => {

    const InfoBox = useMemo(() => {
        return (
            <div className="flex p-8 items-center bg-gray-100 rounded-xl gap-8" >
                <div className="flex-1 flex flex-col items-start justify-between self-stretch" >
                    <p className="text-4xl font-semibold text-primary text-left" >{'Contribute to life-changing causes with your voting power'}</p>
                    <div className="flex items-center gep-4" >
                        <p style={{ backgroundColor: colors.PRIMARY + '15' }} className="text-primary px-6 py-3 font-semibold rounded-full shrink-0 mr-4 text-sm" >10 tokens = 1 voting power</p>
                        <p className="text-start text-sm" >Your donation not only supports these causes but also gives you a voice. Every 10 tokens gives you 1 voting power. The more tokens you earn, the higher voting power you have and the more impact you can have on our communities..</p>
                    </div>
                    <div className="flex items-end mt-8" >
                        <ImageIcon containerStyle={{ backgroundColor: colors.WHITE, borderRadius: 100, padding: 7, marginRight: 16 }} size={26} name="vote" />
                        <div className="flex items-end" >
                            <p className="font-bold text-4xl mr-2" >60</p>
                            <p className="font-semibold" >voting power available</p>
                        </div>
                    </div>
                </div>
                <img className="h-[200px] w-[400px] object-contain" src={Images.illustration_3} />
            </div>
        )
    }, [])

    return (
        <>
            {InfoBox}
            <div className="flex gap-2 items-center mt-12" >
                <p className="mr-4 font-semibold" >Projects</p>
                <Dropdown options={countries} value='worldwide' />
                <Dropdown options={themes} value='all-themes' />
            </div>

            <div
                className="grid grid-cols-4 gap-4 mt-6" >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6].map((bonus, index) => (
                    <ProjectItem key={index} />
                ))}
            </div>
        </>
    )
}

const NFTMarketPlaceView = () => {

    const [selectedTokens, setSelectedTokens] = useState<string[]>([])

    const { user } = useAuth()
    const { useMyNftTokens, useAllNFTs } = useNearWallet()

    const { data: my_nft_tokens = [], isFetching: isFetchingMyNftTokens } = useMyNftTokens()
    const { data, fetchNextPage } = useAllNFTs()
    const all_nft_tokens = useMemo(() => data?.pages.reduce((t, c) => [...t, ...c], []) || [], [data])

    const onTokenSelect = (token_id: string) => {
        setSelectedTokens(v => {
            if (v.includes(token_id)) return v.filter(id => id !== token_id)
            else return [...v, token_id]
        })
    }

    // console.log(all_nft_tokens)

    const InfoBox = useMemo(() => {
        return (
            <div className="flex p-8 items-center bg-gray-100 rounded-xl gap-8" >
                <div className="flex-1 flex flex-col items-start justify-between self-stretch" >
                    <p className="text-4xl font-semibold text-primary text-left" >{'Contribute to sustainability by offsetting Carbon emissions'}</p>
                    <div className="flex items-center gep-4" >
                        <p className="text-start text-sm" >By purchasing environmental sustainability NFTs, you contribute a positive impact on the environment. To offset Carbon emissions, simply input the amount of Carbon emissions you want to offset and we’d handle the selection. You can also individually select the NFTs you want to purchase.</p>
                    </div>
                    <div className="flex gap-3 items-center" >
                        <div className="bg-white rounded-xl flex p-3 items-center gap-4" >
                            <ImageIcon size={20} color={colors.BLACK_60} name='fuel' />
                            <input className="flex-1" placeholder="Emissions to offset" />
                            <ImageIcon containerStyle={{ background: colors.PRIMARY50 + 'aa', height: 30, width: 30, borderRadius: 100, marginTop: -10, marginBottom: -10 }} name='exchange_arrow' />
                            <input className="flex-1" placeholder="Price in USD" />
                            <ImageIcon size={20} color={colors.BLACK_60} name='money' />
                        </div>
                        <CustomButton onClick={() => console.log('purchase')} icon="purchase" label="Purchase" />
                    </div>
                    <CustomCheckbox value={true} label="Auto-select NFTs to meet emissions goal" />
                </div>
                <div>
                    <img className="h-[154px] w-[222px] object-contain" src={Images.illustration_2} />
                    <p className="p-4 rounded-full mt-4" style={{ color: colors.PRIMARY, background: colors.PRIMARY50 + '80' }} >{selectedTokens.length} selected = {my_nft_tokens.filter(token => selectedTokens.includes(token.token_id)).map(token => token.emission).reduce((t, c) => t + c, 0)} kg</p>
                </div>

            </div>
        )
    }, [selectedTokens, my_nft_tokens, all_nft_tokens])

    return (
        <>
            {InfoBox}
            <div className="flex gap-2 items-center mt-12" >
                <p className="mr-4 font-semibold" >NFTs</p>
                <Dropdown options={countries} value='worldwide' />
                <Dropdown options={emissionAmounts} value='1' />
            </div>

            {my_nft_tokens.length === 0 && !!user && !isFetchingMyNftTokens && <p className="text-4xl text-gray-400 text-center mt-20" >You have no NFTs yet!</p>}
            {all_nft_tokens.length === 0 && !user && <p className="text-4xl text-gray-400 text-center mt-20" >Loading...</p>}

            <div

                className="grid grid-cols-6 gap-4 mt-6" >
                {(user ? my_nft_tokens : all_nft_tokens).map((token, index) => (
                    <NFTTokenItem onSelect={() => onTokenSelect(token.token_id)} selected={selectedTokens.includes(token.token_id)} token={token} key={index} />
                ))}


            </div>
            {false && all_nft_tokens.length !== 0 && !user && <div className="flex flex-col justify-center" >
                <p
                    onClick={() => fetchNextPage()}
                    className="px-4 py-2 rounded hover-border cursor-pointer bg-gray-200 mt-8 self-center" >Load More</p>
            </div>}

        </>
    )
}




const countries: DropdownOption[] = [
    {
        value: 'worldwide',
        label: 'Worldwide'
    },
    {
        value: 'us',
        label: 'United States'
    },
    {
        value: 'portugal',
        label: 'portugal'
    }
]

const themes: DropdownOption[] = [
    {
        value: 'all-themes',
        label: 'All Themes'
    },
    {
        value: 'wild-life',
        label: 'Wild List'
    },
    {
        value: 'river-side',
        label: 'River Side'
    },
    {
        value: 'healthcare',
        label: 'Healthcare'
    },
]

const emissionAmounts: DropdownOption[] = [
    {
        value: '1',
        label: '0-1 kgs'
    },
    {
        value: '10',
        label: '1-10 kgs'
    },
    {
        value: '20',
        label: '10-20 kgs'
    },
    {
        value: '100',
        label: '20-100 kgs'
    },
]

const carpoolsTypes = [
    { label: 'Donate', value: 'donate' },
    { label: 'NFT Marketplace', value: 'nft-marketplace' }
] 
