import React, { ReactNode, useContext, useRef } from "react"
import { useMutation } from "react-query"
import { auth, db, functions } from "../config/firebase"
import useLoadingFn from "../hooks/useLoadingFn"
import useSubscriber from "../hooks/useSubscriber"
import { Plan } from "../types/Plan"
import { User } from "../types/User"
import { walletConnection } from "../hooks/useNearWallet"
import Firebase from "firebase/compat/app";

interface AuthContextInterface {
    user?: firebase.default.User | null,
    userData?: User
}

const usersColRef = db.collection('users')

export const userDataRef = React.createRef<User | undefined>()

const AuthContext = React.createContext({} as AuthContextInterface)

const AuthProvider = ({ children }: { children: ReactNode }) => {

    const { data: user } = useSubscriber((observer: (value: firebase.default.User | null) => void) => auth.onAuthStateChanged(v => observer(v)))

    const { data: userData } = useSubscriber((observer: (userData: User) => void) => {
        const unSubscribe = usersColRef.doc(user?.uid).onSnapshot(
            snapshot => {
                const data = new User({ ...snapshot?.data(), id: snapshot.id }, user?.uid)
                //@ts-ignore
                userDataRef.current = data
                return observer(data)
            },
            err => console.log('user data listening', err)
        )
        return unSubscribe
    }, [user])

    return (
        <AuthContext.Provider value={{ user, userData }} >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider

export const useAuth = () => {

    const context = useContext(AuthContext)
    const { user } = context

    return {
        ...context,

        loginWithEmail: useMutation({
            mutationFn: async (data: { email: string, password: string, extra_user_data?: { [key: string]: any } }) => {
                const res = await auth.signInWithEmailAndPassword(data.email, data.password)
                if (data.extra_user_data) await usersColRef.doc(res.user?.uid).update(data.extra_user_data)
                const userData = (await usersColRef.doc(res.user?.uid).get()).data()
                let stripe_customer_id
                if (!userData?.stripe?.customer_id && userData) {
                    const createCustomerFunction = functions.httpsCallable('createStripeCustomer')
                    const { data: stripeCustomer } = await createCustomerFunction({ name: userData.full_name })
                    await usersColRef.doc(res.user?.uid).update('stripe.customer_id', stripeCustomer.id)
                    stripe_customer_id = stripeCustomer.id
                }
                return { uid: res.user?.uid, stripe_customer_id }
            },
            onError: (error: any) => {
                console.log(error)
                if (error.code === 'auth/user-not-found') alert('There was no user found with the email you used!')

                if (error.code === 'auth/wrong-password') alert('Wrong password! Please try again.')

                if (error.code === 'auth/invalid-email') alert('That email address is invalid!')
            }
        }),

        signInWithApple: useMutation({
            mutationFn: async () => {
                let provider = new Firebase.auth.OAuthProvider('apple.com');
                const res = await Firebase.auth().signInWithPopup(provider)
                const userData = (await usersColRef.doc(res.user?.uid).get()).data()
                let stripe_customer_id
                if (!userData?.stripe?.customer_id && userData) {
                    const createCustomerFunction = functions.httpsCallable('createStripeCustomer')
                    const { data: stripeCustomer } = await createCustomerFunction({ name: userData.full_name })
                    await usersColRef.doc(res.user?.uid).update('stripe.customer_id', stripeCustomer.id)
                    stripe_customer_id = stripeCustomer.id
                }
                return { uid: res.user?.uid, stripe_customer_id }
            },
            onError: (error: any) => {
                console.log(error)
                if (error.code === 'auth/user-not-found') alert('There was no user found with the email you used!')

                if (error.code === 'auth/wrong-password') alert('Wrong password! Please try again.')

                if (error.code === 'auth/invalid-email') alert('That email address is invalid!')
            }
        }),

        signInWithGoogle: useMutation({
            mutationFn: async () => {
                let provider = new Firebase.auth.GoogleAuthProvider();
                const res = await Firebase.auth().signInWithPopup(provider)
                const userData = (await usersColRef.doc(res.user?.uid).get()).data()
                let stripe_customer_id
                if (!userData?.stripe?.customer_id && userData) {
                    const createCustomerFunction = functions.httpsCallable('createStripeCustomer')
                    const { data: stripeCustomer } = await createCustomerFunction({ name: userData.full_name })
                    await usersColRef.doc(res.user?.uid).update('stripe.customer_id', stripeCustomer.id)
                    stripe_customer_id = stripeCustomer.id
                }
                return { uid: res.user?.uid, stripe_customer_id }
            },
            onError: (error: any) => {
                console.log(error)
                if (error.code === 'auth/user-not-found') alert('There was no user found with the email you used!')

                if (error.code === 'auth/wrong-password') alert('Wrong password! Please try again.')

                if (error.code === 'auth/invalid-email') alert('That email address is invalid!')
            }
        }),

        signUp: useMutation({
            mutationFn: async (data: { email: string, password: string, full_name: string, extra_user_data?: { [key: string]: any } }) => {
                const res = await auth.createUserWithEmailAndPassword(data.email, data.password)
                await usersColRef.doc(res.user?.uid).set({ email: data.email, full_name: data.full_name, credits: 0, ...data.extra_user_data })
                const createCustomerFunction = functions.httpsCallable('createStripeCustomer')
                const { data: stripeCustomer } = await createCustomerFunction({ name: data.full_name })
                return { uid: res.user?.uid, stripe_customer_id: stripeCustomer.id }
            },
            onError: (error: any) => {
                console.log(error)

                if (error.code === 'auth/email-already-in-use') alert('That email address is already in use!')

                if (error.code === 'auth/operation-not-allowed') alert('Operation not allowed!')

                if (error.code === 'auth/weak-password') alert('Please insert a stronger password.')

                if (error.code === 'auth/invalid-email') alert('That email address is invalid!')
            }
        }),

        updateUserData: useMutation(async (params?: Partial<User>) => {
            const userDocRef = db.collection('users').doc(user?.uid || '')
            return userDocRef.update(params!)
        }),

        loginWithNear: useMutation(async (params?: { account_id: string }) => {
            const instance = functions.httpsCallable('loginWithNear')
            const data = await instance(params)
            if (data.data.success) {
                const token = data.data.token
                const res = await auth.signInWithCustomToken(token)
                const userData = (await usersColRef.doc(res.user?.uid).get()).data()
                if (!userData?.stripe?.customer_id && userData) {
                    const createCustomerFunction = functions.httpsCallable('createStripeCustomer')
                    await createCustomerFunction({ name: userData.full_name })
                }
                return res
            } else throw (data.data)
        }),

        signOut: async () => {
            walletConnection.current?.signOut()
            await auth.signOut()
        },

        upgradeAccount: (type: Plan) => {
            const redirect_link = `https://thecarbongames.web.app/upgrade-account?plan_type=${type}&redirect=true`
            window.open(redirect_link, 'popup', 'width=600,height=550')
        }
    }
}