import React, { ReactNode, useContext, useState } from "react"
import { functions } from "../config/firebase"

// functions.useEmulator('127.0.0.1', 5001)

interface AppContextInterface {
    loading : boolean,
    setLoading : React.Dispatch<React.SetStateAction<boolean>>
}

const AppContext = React.createContext({} as AppContextInterface)

const AppProvider = ({children} : {children : ReactNode})=>{
    const [loading, setLoading] = useState(false)
    return (
        <AppContext.Provider value={{loading, setLoading}} >
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider

export const useApp = ()=>{
    const context = useContext(AppContext)

    return {
        ...context
    }
}