import colors from '../assets/colors';
import Images from '../assets/Images';
import Constants from '../common/Constants';
import CustomButton from '../components/common/CustomButton';
import MapComponent from '../components/common/MapComponent';
import Tabs from '../components/common/Tabs';
import CarsStat from '../components/home/CarsState';
import Pools from '../components/home/Pools';
import Leaderboard from '../components/home/Leaderboard';
import UpgradeAccount from '../components/modals/UpgradeAccount';
import useModal from '../hooks/useModal';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import useEvent, { useSelectedEvent } from '../hooks/useEvent';
import useCarpool, { useSelectedCarpool } from '../hooks/useCarpool';
import { useAuth } from '../providers/AuthProvider';
import { useMemo, useState } from 'react';
import { Marker } from '../types/Marker';
import SubmitEvent, { AddEventModalProps } from '../components/modals/SubmitEvent';
import useCorporate from '../hooks/useCorporate';

const Overview = () => {

    const [selectedEvent] = useSelectedEvent()
    const [selectedCarpool] = useSelectedCarpool()

    const { useMyEvents } = useEvent()
    const { useMyCorporate } = useCorporate()
    const { user, userData, signOut } = useAuth()

    const [carpoolsType, setCarpoolsType] = useState<number | undefined>()
    const [leaderboardType, setLeaderboardType] = useState<number | undefined>()

    const { data: events = [] } = useMyEvents()
    const { data: corporation } = useMyCorporate()

    const { useAllActiveCarpools, useCompletedCarpools } = useCarpool()
    const { data: activeCarpools = [], } = useAllActiveCarpools()
    const { data: completedCarpools = [], } = useCompletedCarpools()
    const carpools = useMemo(() => carpoolsType === 0 ? activeCarpools : completedCarpools, [carpoolsType, activeCarpools, completedCarpools])
    const { show: showSubmitEventModal, ModalComp: SubmitEventModal } = useModal<AddEventModalProps>(({ hide, props }) => <SubmitEvent hide={hide} props={props!} />)

    const activeCarpoolMarkers = useMemo(() => activeCarpools.map((carpool, index) => ({
        location: carpool.current_location || carpool.origin,
        data: carpool
    }) as Marker), [activeCarpools])

    const selectedEventMarker = useMemo(() => {
        if (selectedCarpool) return { location: selectedCarpool.destination, type: 'event' as const }
        if (selectedEvent) return { location: selectedEvent?.location, data: selectedEvent }
    }, [selectedEvent, selectedCarpool])

  

    return (
        <div>
            {SubmitEventModal}
            <MapComponent
                polylines={selectedCarpool?.polyline}
                markers={[
                    ...activeCarpoolMarkers,
                    ...(selectedEventMarker ? [selectedEventMarker] : []),
                    ...events?.map(event => event.marker),
                    ...(corporation?.branches.map(branch=>branch.marker)||[])
                ]} />
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', top: Constants.header_height, left: 0, bottom: 0, right: 0, pointerEvents: 'none', padding: '30px 60px' }} >
                <div style={{ display: 'flex', flexDirection: 'column', width: 400, height: '100%' }} >
                    <Tabs
                        value={carpoolsType}
                        options={carpoolsTypes}
                        onSelect={setCarpoolsType} />

                    {carpoolsType !== undefined && <CarsStat />}
                    {carpoolsType !== undefined && <Pools carpools={carpools} />}

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 400, height: '100%' }} >
                    <Tabs
                        value={leaderboardType}
                        options={leaderboardTypes.filter(item => {
                            if (userData?.selected_plan_type == 'event_manager') return item.value == 1 || item.value == 0
                            if (userData?.selected_plan_type == 'corporate_manager') return item.value == 2 || item.value == 0
                            return item.value == 0
                        })}
                        onSelect={setLeaderboardType} />

                    {leaderboardType !== undefined && <Leaderboard leaderboardType={leaderboardType} />}

                </div>
            </div>

            {
                userData?.plan_type.length === 1 ? <UpgradePlanCard hide={carpoolsType !== undefined} />
                    :
                    userData?.selected_plan_type === 'event_manager' ? <SubmitEventCard onClick={showSubmitEventModal} hide={carpoolsType !== undefined} />
                        :
                        null
            }

        </div>
    )
}

export default Overview



const carpoolsTypes = [
    { label: 'Active Carpools', value: 0 },
    { label: 'Completed Carpools', value: 1 }
]

export const leaderboardTypes = [
    { label: 'User Leaderboard', value: 0 },
    { label: 'Venue Leaderboard', value: 1 },
    { label: 'Office Leaderboard', value: 2 }
]

const UpgradePlanCard = ({ hide }: { hide: boolean }) => {
    const { show, ModalComp } = useModal(({ hide }) => <UpgradeAccount hide={hide} />)
    return (
        <div
            style={{
                position: 'absolute',
                bottom: 50,
                left: hide ? 0 : 50,
                opacity: hide ? 0 : 1,
                background: colors.PRIMARY50,
                display: 'flex',
                width: 430,
                padding: 20,
                borderRadius: 10,
                transition: 'all 200ms ease',
                pointerEvents: hide ? 'none' : 'all'
            }} >
            {ModalComp}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
                <p style={{ fontWeight: '600', fontSize: 18, marginBottom: 5 }} >Upgrade Plan</p>
                <p style={{ textAlign: 'start', fontSize: 12, opacity: .6 }} >Upgrade to our Corporate and Event Management plans.</p>

                <CustomButton
                    onClick={show}
                    iconProps={{ size: 18 }}
                    labelStyle={{ color: colors.BLACK_100 }}
                    style={{ backgroundColor: colors.WHITE, marginTop: 'auto' }}
                    label='Upgrade Account'
                    icon='rocket' />

            </div>

            <img alt='upgrade plan' src={Images.illustration_1} style={{ height: 120 }} />

        </div>
    )
}

const SubmitEventCard = ({ hide, onClick }: { hide: boolean, onClick: () => void }) => {
    return (
        <div style={{
            position: 'absolute',
            bottom: 50,
            left: hide ? 0 : 50,
            opacity: hide ? 0 : 1,
            background: colors.ORANGE,
            display: 'flex',
            width: 430,
            padding: 20,
            borderRadius: 10,
            transition: 'all 200ms ease',
            pointerEvents: hide ? 'none' : 'all'
        }} >

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
                <p style={{ fontWeight: '600', fontSize: 18, marginBottom: 5 }} >Submit Event</p>
                <p style={{ textAlign: 'start', fontSize: 12, opacity: .6 }} >Register to get carpool insights on your event.</p>

                <CustomButton
                    onClick={onClick}
                    labelStyle={{ color: colors.BLACK_100 }}
                    style={{ backgroundColor: colors.WHITE, marginTop: 'auto' }}
                    label='Submit Event'
                    icon='calendar' />

            </div>

            <img alt='submit event' src={Images.illustration_2} style={{ height: 120 }} />

        </div>
    )
}