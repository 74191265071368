import { CSSProperties, useMemo } from "react"
import colors from "../../assets/colors"
import "./component-common.css"

type Props = {
    size?: number
    url?: string
    name?: string
    id?: string
    style? : CSSProperties
}

const Avatar = ({ size = 50, url, id, name, style }: Props) => {
    const initials = useMemo(() => name?.getInitials(), [name])
    return (
        <div style={{
            backgroundImage: `url(${url})`,
            backgroundSize: size*1.5,
            backgroundRepeat : 'no-repeat',
            backgroundPosition : 'center 100%',
            backgroundColor: id?.generateColor(),
            width: size,
            height: size,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius : 100,
            ...style
        }}>
            {!url && <p style={{color : colors.WHITE, padding : 4, fontSize : 13}} >{initials}</p>}
        </div>
    )
}

export default Avatar