import { useNavigate } from "react-router-dom"
import colors from "../assets/colors"
import Constants from "../common/Constants"
import ImageIcon from "../components/common/ImageIcon"
import Icons from "../assets/Icons"
import useStats from "../hooks/useStats"
import useDestination from "../hooks/useDestination"

const Statistics = () => {

    const navigate = useNavigate()

    const { useDestinationsInFocus } = useDestination()
    const { useBasesStats } = useStats()

    const { data: destinations = [] } = useDestinationsInFocus()
    const { data: basicStats } = useBasesStats(destinations.map(destination => destination.id))

    console.log(basicStats)

    return (
        <div className="flex-1 " >
            <div className="p-12 overflow-y-auto" style={{ maxHeight: `calc(100vh - ${Constants.header_height}px)` }}>



                <div className="flex items-center mb-8" >
                    <ImageIcon
                        onClick={() => navigate(-1)}
                        containerStyle={{ padding: 6, background: colors.BLACK_100 + '20', borderRadius: 50, marginRight: 15 }}
                        size={16}
                        name='arrow_left_circle' />
                    <p className="text-2xl font-bold" >Statistics</p>
                </div>

                <div className="flex gap-5" >
                    <StatItem
                        background="#D3E9B9"
                        title="Total Carpools to Events"
                        subTitle="200 carpools completed today"
                        unit="Carpool"
                        icon="car_side"
                        value={basicStats?.totalNumberOfCarpools} />

                    <StatItem
                        background="#F8CBAA"
                        title="Unique Active Users"
                        subTitle="45 new unique active users today"
                        unit="User"
                        icon="people"
                        value={basicStats?.numberOfUniquePassengers} />

                    <StatItem
                        background="#D0E6DE"
                        title="Event CO2 Savings"
                        subTitle="Saved 80% of actual CO2 emission"
                        unit="Kg"
                        icon="fuel"
                        value={446} />

                    <StatItem
                        background="#FBD5E6"
                        title="total Bounty Redeemed"
                        subTitle="677 of 1000 NEAR bounty tokens claimed"
                        unit="NEAR"
                        icon="trophy"
                        value={677} />
                </div>

            </div>

        </div>
    )
}

export default Statistics

const StatItem = ({ background, title, unit, subTitle, icon, value }: {
    background: string,
    title: string,
    unit: string,
    subTitle: string,
    value?: number,
    icon: keyof typeof Icons
}) => {
    return (
        <div
            className="rounded-lg relative flex items-start flex-col p-4 flex-1 gap-3"
            style={{ background }} >
            <p className="text-sm" >{title}</p>
            <p className="font-bold text-xl" >{value||'--'} {unit}{value === 1 ? '' : 's'}</p>
            <div className="flex gap-1 items-center" >
                <ImageIcon name="arrow_trending" />
                <p className="text-xs" >{subTitle}</p>
            </div>
            <ImageIcon size={20} name={icon} containerStyle={{ borderRadius: 40, padding: 5, background: colors.WHITE, position: 'absolute', top: 10, right: 10 }} />
        </div>
    )
}