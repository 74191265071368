import { useMutation, useQuery } from "react-query"
import { cleanObject } from "../common/Utils"
import { db } from "../config/firebase"
import { corporateOnboardingFields } from "../pages/CorporateOnboarding"
import { useAuth } from "../providers/AuthProvider"
import { Corporate } from "../types/Corporate"
import useFirestoreRealtimeDoc from "./useFirestoreRealtimeDoc"
import useStripe from "./useStripe"

const corporationsColRef = db.collection('corporations')
const usersColRef = db.collection('users')

const useCorporate = () => {

    const { userData, signUp, loginWithEmail, updateUserData } = useAuth()
    const { createCheckoutSession } = useStripe()

    return {
        useMyCorporate: () => useFirestoreRealtimeDoc({
            query: corporationsColRef.doc(userData?.corporate || 'alt'),
            select: doc => new Corporate(({ id: doc?.id, ...doc?.data() })),
            deps: [userData?.corporate]
        }),

        useGetCorporate: (id?: string) => useQuery({
            queryKey: ['corporate'],
            queryFn: () => corporationsColRef.doc(id || userData?.corporate).get(),
            select: doc => new Corporate(({ id: doc.id, ...doc?.data() })),
            enabled: !!id || !!userData?.corporate
        }),

        updateCorporate: useMutation({
            mutationFn: async (data: Partial<Corporate>) => corporationsColRef.doc(userData?.corporate || data.id || 'alt').update(JSON.parse(JSON.stringify(data)))
        }),

        copyInviteLink: async () => {
            const link = `https://carbongames.page.link/?${new URLSearchParams({
                link: `https://carbongames/corporate-invite?corporate=${userData?.corporate}`,
                apn: 'com.carbon.games',
                ibi: 'com.carbon.games',
                isi: '1634976154',
                afl: 'https://apps.apple.com/us/app/the-carbon-games-events/id1634976154',
            })}`
            await navigator.clipboard.writeText(link)
            return link
        },

        onboardCorporate: useMutation({
            mutationFn: async (data: Corporate) => {
                const corporateDocRef = corporationsColRef.doc()
                await corporateDocRef.set(JSON.parse(JSON.stringify(data)))
                await updateUserData.mutateAsync({ corporate: corporateDocRef.id, selected_plan_type: 'corporate_manager', plan_type: [...(userData?.plan_type || []), 'corporate_manager'] })
                await createCheckoutSession.mutateAsync({ corporate_id: corporateDocRef.id })
                return { uid: userData?.id, stripe_customer_id: userData?.stripe?.customer_id, corporate_id: corporateDocRef.id }
            }
        })
    }
}

export default useCorporate