
import React, { useMemo } from 'react'
import { UseFormRegister, FieldErrorsImpl, Path, RegisterOptions, FieldValues } from "react-hook-form";
import colors from '../../assets/colors';
import { getPropValue } from '../../common/Utils';

type Props<Fields extends FieldValues> = {
    containerStyle?: React.CSSProperties
    label?: string
    inputProps?: React.InputHTMLAttributes<any>
    multiline?: boolean
    formProps: {
        register: UseFormRegister<Fields>,
        errors: Partial<FieldErrorsImpl<{ [x: string]: any; }>>
        name: Path<Fields>
        options?: Partial<RegisterOptions>
    }
}

const ControlledInput = <Fields extends FieldValues,>({ label, formProps, containerStyle, inputProps, multiline }: Props<Fields>) => {


    const errorMessage = useMemo(() => {
        const error = getPropValue(formProps.errors, formProps.name)
        if (!error) return ''
        if (error?.message) return error.message + ''
        if (error.type === 'pattern') return `Please enter a valid ${label}`
        return `${label || inputProps?.placeholder} is required`
    }, [getPropValue(formProps.errors, formProps.name,)])

    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', alignSelf: 'stretch', ...containerStyle }} >
            {label && <p style={{ fontSize: 14, marginBottom: 6 }} >{label}</p>}

            {
                multiline ? (
                    <textarea
                        {...formProps.register(formProps.name, formProps.options)}
                        {...inputProps}
                        style={{ backgroundColor: colors.BLUE + '12', borderRadius: 5, padding: 12, borderWidth: 0, minWidth: 200, paddingLeft: 16, paddingRight: 16, alignSelf: 'stretch', ...inputProps?.style }} />
                ) : (
                    <input
                        {...formProps.register(formProps.name, formProps.options)}
                        {...inputProps}
                        style={{ backgroundColor: colors.BLUE + '12', borderRadius: 5, padding: 12, borderWidth: 0, minWidth: 100, paddingLeft: 16, paddingRight: 16, alignSelf: 'stretch', ...inputProps?.style }} />
                )
            }

            {!!errorMessage && <p style={{ color: 'red', fontSize: 12, marginTop: 5 }} >{errorMessage}</p>}


        </div>
    )
}

export default ControlledInput