import axios from "axios"
import { ReactNode } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import AppProvider from "./AppProvider"
import AuthProvider from "./AuthProvider"
import { useNearWallet } from "../hooks/useNearWallet"
import useSuccessCallback from "../hooks/useSuccessCallback"
import useStripe from "../hooks/useStripe"
import { EventProvider } from "../hooks/useEvent"

const queryClient = new QueryClient()

const Providers = ({ children }: { children: ReactNode }) => {

    const Children = () => {
        const { useInit: initNearWallet } = useNearWallet()
        const { useSuccessSearchParams } = useSuccessCallback()

        //near wallet init
        initNearWallet()
        //success callbacks
        useSuccessSearchParams()

        return <>{children}</>
    }

    return (
        <QueryClientProvider client={queryClient}>
            <AppProvider>
                <AuthProvider>
                    <EventProvider>
                        <Children />
                    </EventProvider>
                </AuthProvider>
            </AppProvider>
        </QueryClientProvider>
    )
}

export default Providers

export const NEAR_API = axios.create({
    baseURL: 'http://143.198.57.184:9067/'
})