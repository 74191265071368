import { MdDone } from "react-icons/md"
import colors from "../../assets/colors"
import CustomButton from "../common/CustomButton"
import ModalHeader from "../common/ModalHeader"
import { BiRocket } from 'react-icons/bi'
import { useEffect } from "react"
import { useAuth } from "../../providers/AuthProvider"

const UpgradeAccount = ({ hide }: { hide: () => void }) => {
    const { upgradeAccount } = useAuth()

    const PlanItem = ({ plan, highlighted }: { plan: Plan, highlighted?: boolean }) => {

        return (
            <div style={{ display: 'flex', backgroundColor: highlighted ? colors.PRIMARY50 : colors.BLACK_10, padding: 15, borderRadius: 7, flexDirection: 'column' }} >
                <div style={{ opacity: .8, fontSize: 12 }} ><div style={{ display: 'inline', opacity: 1, fontWeight: '500', fontSize: 18 }} >${plan.price}</div> NEAR for lifetime access</div>

                <p style={{ fontWeight: '600', fontSize: 16, marginBottom: 20, marginTop: 20 }} >{plan.name}</p>

                {plan.points.map((point, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                        <MdDone style={{ color: colors.PRIMARY, fontSize: 14, marginRight: 8 }} />
                        <p style={{ fontSize: 12, opacity: .7, letterSpacing: -.3 }} >{point}</p>
                    </div>
                ))}
                <CustomButton
                    onClick={() => upgradeAccount(plan.type)}
                    labelStyle={{ color: highlighted ? colors.WHITE : colors.PRIMARY }}
                    IconComp={<BiRocket color={highlighted ? colors.WHITE : colors.PRIMARY} />}
                    label="Get Started"
                    style={{ marginTop: 'auto', backgroundColor: highlighted ? colors.PRIMARY : colors.WHITE }} />
            </div>
        )
    }

    return (
        <div style={{}}>
            <ModalHeader hide={hide} title="Upgrade Account" subtitle="Select the account type that meets your needs" />

            <div style={{ display: 'flex', gap: 20, marginTop: 30 }} >
                <PlanItem plan={plans[0]} highlighted />
                <PlanItem plan={plans[1]} />
            </div>
        </div>
    )
}

export default UpgradeAccount

type Plan = {
    price: number;
    name: string;
    points: string[];
    type : 'corporate_manager' | 'event_manager'
}

const plans: Plan[] = [
    {
        price: 20,
        name: 'Event Manager',
        type: 'event_manager',
        points: [
            'Specify your target market you write for',
            'Export your results to a downloadable file',
            'Take advantage of Forever-Free Plan',
            'Perfect result on the 1st try',
            'Save time writing copy'
        ]
    },
    {
        price: 50,
        name: 'Corporate Manager',
        type: 'corporate_manager',
        points: [
            'Export your results to a downloadable file',
            'Write for products, services and apps',
            'Save the results you liked the most',
            'Take advantage of Forever-Free Plan ',
            'CopyAim will write about your brand',
            'Specify your target market you write for'
        ]
    }
]