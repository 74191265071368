import { SubmitHandler, useForm } from "react-hook-form"
import { useToggle } from "react-use"
import colors from "../../assets/colors"
import Images from "../../assets/Images"
import { useNearWallet } from "../../hooks/useNearWallet"
import { Event } from "../../types/Event"
import ControlledInput from "../common/ControlledInput"
import ControlledPicker from "../common/ControlledPicker"
import CustomButton from "../common/CustomButton"
import CustomCheckbox from "../common/CustomCheckbox"
import ModalHeader from "../common/ModalHeader"
import firebase from 'firebase/compat/app'
import useEvent from "../../hooks/useEvent"
import { db } from "../../config/firebase"

export type SponsorEventModalProps = {
    event: Event
}

type Fields = {
    amount: number,
    currency: string
}

const SponsorEvent = ({ hide, props }: { hide: () => void, props: SponsorEventModalProps }) => {
    const event = props.event
    const [donateAnonymously, toggleDonateAnonymously] = useToggle(false)
    const { register, control, handleSubmit, formState: { errors } } = useForm<Fields>({ defaultValues: { currency: 'near' } })
    const { sponsorEvent } = useNearWallet()
    const { updateEvent } = useEvent()

    const onSubmit: SubmitHandler<Fields> = (data) => {
        updateEvent.mutate({ event_id: event.id, data: { bounty: firebase.firestore.FieldValue.increment(data.amount) as any } })
        sponsorEvent({ event_id: event.id, amount: data.amount })
    }

    return (
        <div style={{}}>
            <ModalHeader hide={hide} title="Sponsor Event" subtitle="Your contribution goes a long way to incentivize social development goals" />

            <div style={{ background: colors.ORANGE + 'aa', borderRadius: 8, padding: 15, marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                <div>
                    <div style={{ display: 'flex', alignItems: 'flex-end', gap: 5 }} >
                        <p style={{ fontWeight: '600', fontSize: 18 }} >$0</p>
                        <p style={{ fontWeight: '500', fontSize: 12 }} >NEAR</p>
                        <p style={{ fontSize: 12 }} >Total Contributions</p>
                    </div>

                    <p style={{ opacity: .6, fontSize: 12, marginTop: 10 }} >From 0 Unique Sponsors</p>

                    <p style={{ fontSize: 14, fontWeight: '600', marginTop: 15 }} >{event.name}</p>
                </div>

                <img alt="hexagons" src={Images.hexagon_illustration} style={{ height: 50 }} />

            </div>

            <div style={{ display: 'flex', marginTop: 15, alignItems: 'flex-end', background: colors.BLUE + '12', borderRadius: 12, marginBottom: 20 }} >
                <ControlledInput
                    containerStyle={{ flex: 1, backgroundColor: colors.TRANSPARENT }}
                    inputProps={{ placeholder: 'Amount to Donate', style: { backgroundColor: colors.TRANSPARENT } }}
                    formProps={{ register, errors, name: 'amount', options: { required: true } }} />
                <div style={{ width: 1, background: colors.BLACK_20, alignSelf: 'stretch', marginLeft: 1 }} />
                <ControlledPicker
                    label='Locked'
                    hideLabel
                    containerStyle={{ alignSelf: 'flex-end', backgroundColor: colors.TRANSPARENT }}
                    options={[{ label: 'NEAR', value: 'near' }]}
                    formProps={{ errors, name: 'currency', options: { required: true }, control }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }} >
                <CustomCheckbox containerStyle={{ marginRight: 6 }} value={donateAnonymously} onClick={toggleDonateAnonymously} />
                <p style={{ fontSize: 12 }} >Donate Anonymously</p>
                <p style={{ fontSize: 8, paddingTop: 3, paddingLeft: 2 }} >(You details will not show up on the Sponsor Leaderboard)</p>
            </div>

            <CustomButton
                onClick={handleSubmit(onSubmit)}
                label="Sponsor Event"
                icon='sponsor' />
        </div>
    )
}

export default SponsorEvent
