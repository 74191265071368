import { useQuery } from "react-query"
import { createGlobalState } from "react-use"
import { db } from "../config/firebase"
import { User } from "../types/User"
import useFirestoreRealtimeData from "./useFirestoreRealtimeData"

const usersColRef = db.collection('users')

export const useSelectedUser = createGlobalState<User|undefined>()

const useUser = () => {
    return {
        useUserLeaderboard: () => useFirestoreRealtimeData({
            query: usersColRef.orderBy('credits', 'desc').limit(20),
            select: (snapshot) => {
                return snapshot?.docs.map(doc=>new User({id : doc.id, ...doc.data()},doc.id ))
            },
        }),

        useIdsToUsers : (ids: string[])=> useQuery({
            queryKey : ['ids-to-users', ids],
            queryFn : async()=> Promise.all(ids.map(id=>usersColRef.doc(id).get())),
            select: (snapshot) => {
                return snapshot?.map(doc=>new User({id : doc.id, ...doc.data()},doc.id ))
            },
        }),

        useCorporateUsers : (corporateId : string)=>useFirestoreRealtimeData({
            query : usersColRef.where('corporate', '==', corporateId),
            select : (snapshot) => {
                return snapshot?.docs.map(doc=>new User({id : doc.id, ...doc.data()},doc.id ))
            },
            deps : [corporateId]
        })
    }
}

export default useUser