import colors from '../assets/colors';
import Avatar from '../components/common/Avatar';
import CustomButton from '../components/common/CustomButton';
import Header from '../components/layout/Header';
import useModal from '../hooks/useModal';
import { GrDown } from 'react-icons/gr'
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import ImageIcon from '../components/common/ImageIcon';
import Icons from '../assets/Icons';
import SwitchAccounts from '../components/modals/SwitchAccounts';
import SubmitEvent, { AddEventModalProps } from '../components/modals/SubmitEvent';
import { useAuth } from '../providers/AuthProvider';
import { useNearWallet } from '../hooks/useNearWallet';
import EventSubmissions, { EventSubmissionModalProps } from '../components/modals/EventSubmitssions';
import CreateBounty, { CreateBountyModalProps } from '../components/modals/CreateBounty';
import EditCorporateDetails, { EditCorporateModalProps } from '../components/modals/EditCorporateDetails';
import useCorporate from '../hooks/useCorporate';
import { Outlet } from 'react-router-dom';
import SignInSignUp from '../components/modals/SignInSignUp';

function Home() {

    // const [selectedEvent] = useSelectedEvent()
    // const [selectedCarpool] = useSelectedCarpool()

    const { user, userData, signOut } = useAuth()
    const { signIn: connectWallet } = useNearWallet()

    const { useMyCorporate } = useCorporate()
    const { data: corporate } = useMyCorporate()

    // const [carpoolsType, setCarpoolsType] = useState<number | undefined>()
    // const [leaderboardType, setLeaderboardType] = useState<number | undefined>()

    // const { useAllActiveCarpools, useCompletedCarpools } = useCarpool()
    // const { data: activeCarpools = [], } = useAllActiveCarpools()
    // const { data: completedCarpools = [], } = useCompletedCarpools()
    // const carpools = useMemo(() => carpoolsType === 0 ? activeCarpools : completedCarpools, [carpoolsType, activeCarpools, completedCarpools])
    const { show: showSubmitEventModal, ModalComp: SubmitEventModal } = useModal<AddEventModalProps>(({ hide, props }) => <SubmitEvent hide={hide} props={props!} />)
    const { show: showEventSubmissionModal, hide: hideEventSubmissionsModal, ModalComp: EventSubmissionsModal } = useModal<EventSubmissionModalProps>(({ hide, props }) => <EventSubmissions props={props!} hide={hide} />)
    const { show: showEditCorporateDetailsModal, ModalComp: EditCorporateDetailsModal } = useModal<EditCorporateModalProps>(({ hide, props }) => <EditCorporateDetails props={props!} hide={hide} />)
    const { show: showCreateBountyModal, ModalComp: CreateBountyModal } = useModal<CreateBountyModalProps>(({ hide, props }) => <CreateBounty hide={hide} props={props!} />)
    const {show : showSignInSignUp, ModalComp : SignInSignUpModal} = useModal(({hide, props})=><SignInSignUp hide={hide} />)


    const HeaderRightComponent = () => {

        const { show: showSwitchAccountsModal, ModalComp: SwitchAccountsModal } = useModal(({ hide }) => <SwitchAccounts hide={hide} />)

        const CustomMenuItem = ({ icon, label, tint, onSelect }: { icon: keyof typeof Icons, label: string, tint?: string, onSelect?: () => void }) => {
            return (
                <MenuItem  >
                    <div onClick={onSelect} style={{ display: 'flex', alignItems: 'center', gap: 5 }} >
                        <ImageIcon size={18} name={icon} />
                        <p style={{ fontSize: 14, color: tint }} >{label}</p>
                    </div>
                </MenuItem>
            )
        }

        if (user) return (
            <div style={{ display: 'flex', alignItems: 'center' }} >
                {SwitchAccountsModal}

                {userData?.selected_plan_type === 'event_manager' && <CustomButton onClick={showSubmitEventModal} icon="calendar" iconProps={{ color: colors.WHITE }} label={'Submit Event'} />}
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }} >
                    <Avatar
                        id={user.uid}
                        name={userData?.full_name}
                        url={userData?.avatar}
                        size={40} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginLeft: 5 }} >

                        <Menu
                            menuStyle={{ borderRadius: 10 }}
                            menuButton={(
                                <div className='hover-tint' style={{ display: 'flex', alignItems: 'center', padding: 5, cursor: 'pointer' }}>
                                    <p style={{ fontWeight: '500', fontSize: 14 }} >{userData?.full_name}</p>
                                    <GrDown style={{ fontSize: 12, marginLeft: 5 }} />
                                </div>
                            )} transition>
                            {userData?.selected_plan_type === 'event_manager' && <CustomMenuItem onSelect={() => showEventSubmissionModal({ onSelectEvent: (event) => showSubmitEventModal({ event }) })} tint='#000' label='Event Submissions' icon='event_account' />}
                            {userData?.selected_plan_type === 'corporate_manager' && <CustomMenuItem onSelect={() => showEditCorporateDetailsModal({ corporate: corporate! })} tint='#000' label='Edit corporate details' icon='event_account' />}
                            <CustomMenuItem onSelect={showSwitchAccountsModal} tint='#000' label='Switch Mode' icon='switch_accounts' />
                            <CustomMenuItem onSelect={signOut} tint={colors.RED} label='Disconnect wallet' icon='logout' />
                        </Menu>

                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                            <p style={{ fontSize: 12, opacity: .7 }} >{user.uid.slice(0, 5)}...{user.uid.slice(-3)}</p>
                            {userData?.plan_type?.[0] && <div style={{ height: 4, width: 4, background: colors.PRIMARY, borderRadius: 10, margin: '0 7px' }} />}
                            {userData?.plan_type?.[0] && < p style={{ fontSize: 12, opacity: .7 }} >{userData?.selected_plan_type?.replaceAll(/_/g, ' ').capitalizeEveryWord()}</p>}
                        </div>

                    </div>
                </div>
            </div >
        )
        else return (
            <div className='flex items-center gap-4' >
                <CustomButton onClick={connectWallet} icon="wallet" label={'Connect Wallet'} />
                <CustomButton onClick={showSignInSignUp} icon="switch_accounts" iconProps={{color : colors.WHITE}} label={'Sign In'} />
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height : '100%' }}  >
            {SubmitEventModal}
            {EventSubmissionsModal}
            {corporate && EditCorporateDetailsModal}
            {CreateBountyModal}
            {SignInSignUpModal}
            <Header right={<HeaderRightComponent />} />
            <div style={{ flex: 1, display : 'flex', flexDirection : 'column' }} >
                <Outlet />
            </div>


        </div>

    );
}

export default Home;

const carpoolsTypes = [
    { label: 'Active Carpools', value: 0 },
    { label: 'Completed Carpools', value: 1 }
]

export const leaderboardTypes = [
    { label: 'User Leaderboard', value: 0 },
    { label: 'Venue Leaderboard', value: 1 },
    { label: 'Office Leaderboard', value: 2 }
]
