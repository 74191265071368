import './App.css';
import 'react-circular-progressbar/dist/styles.css';
import Home from './pages/Home';
import colors from './assets/colors';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useEffect, useRef } from 'react';
import { useApp } from './providers/AppProvider';
import { useIsFetching, useIsMutating } from 'react-query';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from './providers/AuthProvider';
import Overview from './pages/Overview';
import Bonuses from './pages/Bonuses';
import Marketplace from './pages/Marketplace';
import DonationHistory from './pages/DonationHistory';
import CorporateOnboarding from './pages/CorporateOnboarding';
import EventOnboarding from './pages/EventOnboarding';
import ComingSoon from './pages/ComingSoon';
import { useSearchParam } from 'react-use';
import ActiveCarpoolDetail from './pages/ActiveCarpoolDetail';
import Statistics from './pages/Statistics';



function App() {

  const { user } = useAuth()

  if (user === undefined) return null

  return (


    < div style={{ height: '100vh', }} className="App" >
      <TopLoadingBar />

      <Routes>

        <Route path='/:slug?' element={<Home />} >

          <Route index element={<Overview />} />

          <Route path='statistics' element={<Statistics />} />
          <Route path='trip-history' element={<ComingSoon />} />
          <Route path='badge-and-nfts' element={<ComingSoon />} />
          <Route path='settings' element={<ComingSoon />} />

          <Route path='bonuses' element={<Bonuses />} />

          <Route path='marketplace' >
            <Route index element={<Marketplace />} />
            <Route path='donation-history' element={<DonationHistory />} />
          </Route>

          <Route path='active-carpool/:id' element={<ActiveCarpoolDetail />} />

        </Route>

        <Route path='corporate-onboarding' element={<CorporateOnboarding />} />
        <Route path='event-onboarding' element={<EventOnboarding />} />

      </Routes>
    </div >

  );
}

export default App;

const TopLoadingBar = () => {
  const ref = useRef<LoadingBarRef>(null);
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  const { loading } = useApp()

  useEffect(() => {
    if (loading || !!isFetching || !!isMutating) ref.current?.continuousStart()
    else ref.current?.complete()
  }, [loading, isFetching, isMutating])

  return (
    <LoadingBar
      color={colors.PRIMARY}
      ref={ref} />
  )

}
