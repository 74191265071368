import { useMemo, useRef } from "react"
import { MdCopyAll } from "react-icons/md"
import colors from "../../assets/colors"
import useBounty from "../../hooks/useBounty"
import useCorporate from "../../hooks/useCorporate"
import useEvent, { useSelectedEvent } from "../../hooks/useEvent"
import useModal from "../../hooks/useModal"
import useUser, { useSelectedUser } from "../../hooks/useUser"
import { leaderboardTypes } from "../../pages/Home"
import { useAuth } from "../../providers/AuthProvider"
import { CorporateBranch } from "../../types/Corporate"
import { Event } from "../../types/Event"
import { User } from "../../types/User"
import Avatar from "../common/Avatar"
import Card from "../common/Card"
import ImageIcon from "../common/ImageIcon"
import { useMapCenter } from "../common/MapComponent"
import EventSummary, { EventSummaryModalProps } from "../modals/EventSummary"
import SponsorEvent, { SponsorEventModalProps } from "../modals/SponsorEvent"

const Leaderboard = ({ leaderboardType: selectedLeaderboardValue }: { leaderboardType: number }) => {

    const { userData } = useAuth()
    const { useMyBounties } = useBounty()
    const { data: bounties = [] } = useMyBounties()
    const { useIdsToUsers } = useUser()
    const { useVenueLeaderboard } = useEvent()
    const { data: users = [], } = useIdsToUsers(bounties.reduce<string[]>((t, c) => [...t, ...(c.claimants || [])], []))
    const { data: events = [] } = useVenueLeaderboard()

    const listHeaderRef = useRef<HTMLDivElement>(null)

    const selectedLeaderboardType = leaderboardTypes.find(l => l.value == selectedLeaderboardValue)

    const List = useMemo(() => {
        if (selectedLeaderboardValue === 0) {
            if (userData?.selected_plan_type == 'event_manager') return <BountyParticipantList listHeaderRef={listHeaderRef} />
            if (userData?.selected_plan_type == 'corporate_manager') return <CorporateUserList listHeaderRef={listHeaderRef} />
        } else if (selectedLeaderboardValue === 1) return <EventList listHeaderRef={listHeaderRef} />
        else if (selectedLeaderboardValue === 2) return <OfficeList listHeaderRef={listHeaderRef} />
        else return null
    }, [selectedLeaderboardValue, users, events])

    return (
        <Card style={{ marginTop: 20, position: 'relative', pointerEvents: 'all', flex: 1, overflowY: 'auto', minWidth: 400 }} >
            <div ref={listHeaderRef} style={{ padding: 20, paddingBottom: 5, borderStyle: 'dashed', borderColor: colors.BLACK_20, borderWidth: 0, borderBottomWidth: 0 }} >
                <p style={{ textAlign: 'start', fontWeight: '600' }} >{selectedLeaderboardType?.label}</p>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 20, margin: '0 10px', opacity: .5 }} >
                    <p style={{ fontSize: 14 }} >#</p>
                    <p style={{ fontSize: 14, marginLeft: 50 }} >{selectedLeaderboardType?.label.split(' ')[0]}</p>
                    <p style={{ fontSize: 14, marginLeft: 'auto' }} >{selectedLeaderboardValue == 1 ? 'Bounty' : 'Claimed'}</p>
                </div>
            </div>
            {List}
        </Card>
    )
}

export default Leaderboard

const OfficeList = ({ listHeaderRef, }: { listHeaderRef: React.RefObject<HTMLDivElement> }) => {
    const { useMyCorporate } = useCorporate()
    const { data: corporate } = useMyCorporate()
    return (
        <div
            onScrollCapture={e => listHeaderRef.current!.style.borderBottomWidth = (e.target as any).scrollTop > 25 ? '1px' : '0px'}
            style={{ overflowY: 'auto', gap: 15, display: 'flex', flexDirection: 'column', padding: 20, alignItems: 'stretch' }}>
            {corporate?.branches.map((item, index) => <OfficeItem
                index={index}
                // onClick={() => { showEventSummaryModal({ event: item }); setSelectedEvent(item); setCenter({ lat: item.location.coordinates.latitude, lng: item.location.coordinates.longitude }) }}
                selected={false}
                office={item}
                key={index} />)}
        </div>
    )
}

const OfficeItem = ({ office, selected, onClick, index }: { office: CorporateBranch, selected: boolean, index: number, onClick?: () => void }) => {
    return (
        <div
            onClick={onClick}
            className={`hover-tint ${selected ? 'selected' : ''}`}
            style={{ display: 'flex', alignItems: 'center', padding: 5, cursor: 'pointer', }} >
            <div style={{ height: 30, width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 20, position: 'relative' }} >
                <ImageIcon color="#999999" size={30} containerStyle={{ opacity: index < 3 ? .4 : .2, position: 'absolute' }} name={'hexagon_2'} />
                <p style={{ opacity: .6, fontWeight: '600', fontSize: 12 }} >{index + 1}</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                <p style={{ fontWeight: '700', textAlign: 'start', fontSize: 14 }}>{office.name?.trim()}</p>
                <p style={{ fontWeight: '400', fontSize: 10, textAlign: 'start' }}>{office?.location?.address}</p>
            </div>
        </div>
    )
}

const EventList = ({ listHeaderRef, }: { listHeaderRef: React.RefObject<HTMLDivElement> }) => {
    const { useVenueLeaderboard } = useEvent()
    const { data: events = [] } = useVenueLeaderboard()
    const { show: showEventSummaryModal, ModalComp: EventSummaryModal } = useModal<EventSummaryModalProps>(({ props, hide }) => <EventSummary hide={hide} props={props!} />)
    const [selectedEvent, setSelectedEvent] = useSelectedEvent()
    const [center, setCenter] = useMapCenter()
    return (
        <div
            onScrollCapture={e => listHeaderRef.current!.style.borderBottomWidth = (e.target as any).scrollTop > 25 ? '1px' : '0px'}
            style={{ overflowY: 'auto', gap: 15, display: 'flex', flexDirection: 'column', padding: 20, alignItems: 'stretch' }}>
            {EventSummaryModal}
            {events.map((item, index) => <EventItem
                index={index}
                onClick={() => { showEventSummaryModal({ event: item }); setSelectedEvent(item); setCenter({ lat: item.location.coordinates.latitude, lng: item.location.coordinates.longitude }) }}
                selected={item.id === selectedEvent?.id}
                event={item}
                key={index} />)}
        </div>
    )
}

const EventItem = ({ event, selected, onClick, index }: { event: Event, selected: boolean, index: number, onClick: () => void }) => {
    const { show: showSponsorModal, ModalComp: SponsorEventModal } = useModal<SponsorEventModalProps>(({ hide, props }) => <SponsorEvent props={props!} hide={hide} />)
    return (
        <div
            onClick={onClick}
            className={`hover-tint ${selected ? 'selected' : ''}`}
            style={{ display: 'flex', alignItems: 'center', padding: 5, cursor: 'pointer', }} >
            {SponsorEventModal}
            <div style={{ height: 30, width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 20, position: 'relative' }} >
                <ImageIcon color="#999999" size={30} containerStyle={{ opacity: index < 3 ? .4 : .2, position: 'absolute' }} name={'hexagon_2'} />
                <p style={{ opacity: .6, fontWeight: '600', fontSize: 12 }} >{index + 1}</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} >
                <p style={{ fontWeight: '700', textAlign: 'start', fontSize: 14 }}>{event.name?.trim()}</p>
                <p style={{ fontWeight: '400', fontSize: 10, textAlign: 'start' }}>{event.location.address}</p>
            </div>

            <div
                className="hover-scale hover-border"
                onClick={(e) => { e.stopPropagation(); showSponsorModal({ event }) }}
                style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', background: colors.LIGHT_GREEN + '90', padding: '8px 16px', borderRadius: 50, cursor: 'pointer' }} >
                <ImageIcon containerStyle={{ cursor: 'pointer' }} name={'near'} />
                <p style={{ fontWeight: '600', fontSize: 14, marginLeft: 5, }} >{event.bounty?.toFixed(0)}</p>
            </div>
        </div>
    )
}

const BountyParticipantList = ({ listHeaderRef, }: { listHeaderRef: React.RefObject<HTMLDivElement> }) => {
    const { useMyBounties } = useBounty()
    const { data: bounties = [] } = useMyBounties()
    const { useIdsToUsers } = useUser()
    const { data: users = [], } = useIdsToUsers(bounties.reduce<string[]>((t, c) => [...t, ...(c.claimants || [])], []))
    const [selectedUser, setSelectedUser] = useSelectedUser()
    const TOTAL_BOUNTY = bounties.reduce((t, c) => (t || 0) + (parseFloat(c.total_bounty + '') || 0), 0)
    return (
        <div
            onScrollCapture={e => listHeaderRef.current!.style.borderBottomWidth = (e.target as any).scrollTop > 25 ? '1px' : '0px'}
            style={{ overflowY: 'auto', gap: 15, display: 'flex', flexDirection: 'column', padding: 20, alignItems: 'stretch' }}>
            {users.map((item, index) => <BountyParticipantItem
                index={index}
                selected={item.id === selectedUser?.id}
                onClick={() => setSelectedUser(item)}
                user={item}
                amount={TOTAL_BOUNTY / users.length}
                key={index} />)}
        </div>
    )
}

const BountyParticipantItem = ({ user, onClick, selected, index, amount }: { user: User, onClick: () => void, selected: boolean, index: number, amount: number }) => {
    return (
        <div
            onClick={onClick}
            className={`hover-tint ${selected ? 'selected' : ''}`}
            style={{ display: 'flex', alignItems: 'center', padding: 5, cursor: 'pointer', }} >
            <div style={{ height: 30, width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 20, position: 'relative' }} >
                <ImageIcon color="#999999" size={30} containerStyle={{ opacity: index < 3 ? .4 : .2, position: 'absolute' }} name={'hexagon_2'} />
                <p style={{ opacity: .6, fontWeight: '600', fontSize: 12 }} >{index + 1}</p>
            </div>


            <Avatar
                id={user.id}
                name={user.full_name || 'Anonymous User'}
                url={user.avatar}
                size={35} />

            <div style={{ marginLeft: 10, }} >
                <p style={{ fontWeight: '700', textAlign: 'start', fontSize: 14 }}>{user.full_name || 'Anonymous User'}</p>
                <p style={{ fontWeight: '400', fontSize: 10, textAlign: 'start' }}>@{user.username}</p>
            </div>


            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', background: colors.LIGHT_GREEN + '90', padding: '8px 16px', borderRadius: 50 }} >
                <ImageIcon name={'near'} />
                <p style={{ fontWeight: '600', fontSize: 14, marginLeft: 5, }} >{amount.toFixed(0)}</p>
            </div>
        </div>
    )
}

const CorporateUserList = ({ listHeaderRef, }: { listHeaderRef: React.RefObject<HTMLDivElement> }) => {

    const { userData } = useAuth()
    const { useCorporateUsers } = useUser()
    const { data: users = [] } = useCorporateUsers(userData?.corporate || "")
    const [selectedUser, setSelectedUser] = useSelectedUser()
    const { copyInviteLink } = useCorporate()

    return (
        <div
            onScrollCapture={e => listHeaderRef.current!.style.borderBottomWidth = (e.target as any).scrollTop > 25 ? '1px' : '0px'}
            style={{ overflowY: 'auto', gap: 15, display: 'flex', flexDirection: 'column', padding: 20, alignItems: 'stretch' }}>
            {users.map((item, index) => <CorporateUserItem
                index={index}
                selected={item.id === selectedUser?.id}
                onClick={() => setSelectedUser(item)}
                user={item}
                key={index} />)}
            <div
                onClick={copyInviteLink}
                className="hover-border"
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, background: colors.BLACK_10, gap: 2, padding: 10, cursor: 'pointer' }} >
                <MdCopyAll />
                <p style={{ fontSize: 12 }} >INVITE LINK</p>
            </div>
        </div>
    )
}

const CorporateUserItem = ({ user, onClick, selected, index }: { user: User, onClick: () => void, selected: boolean, index: number }) => {
    return (
        <div
            onClick={onClick}
            className={`hover-tint ${selected ? 'selected' : ''}`}
            style={{ display: 'flex', alignItems: 'center', padding: 5, cursor: 'pointer', }} >
            <div style={{ height: 30, width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 20, position: 'relative' }} >
                <ImageIcon color="#999999" size={30} containerStyle={{ opacity: index < 3 ? .4 : .2, position: 'absolute' }} name={'hexagon_2'} />
                <p style={{ opacity: .6, fontWeight: '600', fontSize: 12 }} >{index + 1}</p>
            </div>


            <Avatar
                id={user.id}
                name={user.full_name || 'Anonymous User'}
                url={user.avatar}
                size={35} />

            <div style={{ marginLeft: 10, }} >
                <p style={{ fontWeight: '700', textAlign: 'start', fontSize: 14 }}>{user.full_name || 'Anonymous User'}</p>
                <p style={{ fontWeight: '400', fontSize: 10, textAlign: 'start' }}>@{user.username}</p>
            </div>

        </div>
    )
}
