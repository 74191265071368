import colors from "../../assets/colors"
import Icons from "../../assets/Icons"
import Images from "../../assets/Images"
import useModal from "../../hooks/useModal"
import { Event } from "../../types/Event"
import CustomButton from "../common/CustomButton"
import ImageIcon from "../common/ImageIcon"
import ModalHeader from "../common/ModalHeader"
import CreateBounty, { CreateBountyModalProps } from "./CreateBounty"

export type EventSummaryModalProps = {
    event: Event,
}

type Fields = {
    amount: number,
    currency: string
}

const EventSummary = ({ hide, props }: { hide: () => void, props: EventSummaryModalProps }) => {
    const event = props.event


    const Detail = ({ icon, label, value, unit }: { icon: keyof typeof Icons, label: string, value: string, unit: string }) => (
        <div style={{display : 'flex', alignItems : 'center', gap :10}} >
            <ImageIcon size={22} containerStyle={{ padding: 8, background: colors.BLACK_10, borderRadius: 40, }} name={icon} />
            <div>
                <p style={{fontSize : 10, marginBottom : 5}} >{label}</p>
                <div style={{ display: 'flex', alignItems: 'flex-end' }} >
                    <p style={{fontWeight : 'bold', fontSize : 12}} >{value}</p>
                    <p style={{fontSize : 8, marginLeft : 3}} >{unit}</p>
                </div>

            </div>
        </div>
    )

    return (
        <div style={{ minWidth: 500 }}>
          
            <ModalHeader hide={hide} title="Event Summary" subtitle={`${event.name}, ${event.eventFormattedDate}`} />

            <div style={{ display: 'flex', marginTop: 40, gap: 8 }} >
                <ImageIcon name='verified' />
                <p style={{ fontSize: 14, fontWeight: '600' }} >{event.name}</p>
            </div>
            <div style={{ display: 'flex', marginTop: 10, gap: 8 }} >
                <ImageIcon name='location' />
                <p style={{ fontSize: 12, }} >{event.venue}</p>
            </div>
            <div style={{ display: 'flex', marginTop: 10, gap: 8 }} >
                <ImageIcon name='calendar_date' />
                <p style={{ fontSize: 12, }} >{event.eventFormattedDate}</p>
            </div>

            <div style={{ background: colors.LIGHT_GREEN + 'aa', borderRadius: 8, padding: 15, marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >


                <div style={{ gap: 5, alignSelf: 'stretch', display: 'flex', flexDirection: 'column', alignItems: 'start' }} >
                    <ImageIcon size={25} containerStyle={{ padding: 8, background: colors.WHITE, borderRadius: 40, marginBottom: 10 }} name='fuel' />
                    <p style={{ fontWeight: '600', fontSize: 18, marginBottom: 5 }} >4067</p>
                    <p style={{ fontSize: 12 }} >Carbon Emissions for Audited Period in Tonnes</p>
                </div>


                <img alt="illustration" src={Images.illustration_3} style={{ height: 75 }} />

            </div>

            <div style={{display : 'flex', justifyContent : 'space-between', marginTop : 30}} >
                <Detail icon='car_side' label="Total Carpools" value="456" unit="Carpools" />
                <Detail icon='people' label="No. of Passengers" value="2436" unit="Carpoolers" />
                <Detail icon='globe' label="Distance Traveled" value="266" unit="Km" />
            </div>

        </div>
    )
}

export default EventSummary
