import colors from "../../assets/colors"
import ModalHeader from "../common/ModalHeader"
import Icons from "../../assets/Icons"
import ImageIcon from "../common/ImageIcon"
import CustomButton from "../common/CustomButton"
import { useMemo, useState } from "react"
import { BiRocket } from "react-icons/bi"
import { useAuth } from "../../providers/AuthProvider"
import { Plan } from "../../types/Plan"
import UpgradeAccount from "./UpgradeAccount"

const SwitchAccounts = ({ hide }: { hide: () => void }) => {

    const { userData, upgradeAccount, updateUserData } = useAuth()
    const [selectedIndex, setSelectedIndex] = useState(accountTypes.map(accountType => accountType.plan_type).indexOf(userData?.selected_plan_type!))
    const selectedAccountType = useMemo(() => accountTypes[selectedIndex], [selectedIndex])
    const selectedAccountAvailable = userData?.plan_type.includes(selectedAccountType?.plan_type)

    const onPress = () => {
        hide()
        if (selectedAccountAvailable) updateUserData.mutate({ selected_plan_type: selectedAccountType.plan_type })
        else upgradeAccount(selectedAccountType.plan_type)
    }

    const AccountTypeItem = ({ accountType, highlighted, index }: { accountType: AccountType, highlighted?: boolean, index: number }) => {

        return (
            <div
                onClick={() => setSelectedIndex(index)}
                className="hover-scale"
                style={{ display: 'flex', backgroundColor: highlighted ? colors.PRIMARY50 : colors.BLACK_10, padding: 15, borderRadius: 7, alignItems: 'center', cursor: 'pointer', gap: 10 }} >
                <ImageIcon
                    size={20}
                    containerStyle={{ padding: 7, backgroundColor: colors.WHITE, borderRadius: 50 }}
                    name={accountType.icon} />
                <div>
                    <p style={{ fontWeight: '500', fontSize: 14, marginBlock: 5 }} >{accountType.label}</p>
                    <p style={{ fontSize: 12, opacity: .6 }} >{accountType.description}</p>
                </div>
            </div>
        )
    }

    return (
        <div style={{}}>
            <ModalHeader hide={hide} title="Switch Mode" subtitle="Seamlessly make use of your Event and Corporate dashboards" />

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 30 }} >
                {accountTypes.map((accountType, index) => <AccountTypeItem key={index} index={index} accountType={accountType} highlighted={selectedIndex === index} />)}
            </div>

            <CustomButton
                onClick={onPress}
                style={{ marginTop: 40 }}
                icon={"switch_accounts"}
                iconProps={{ color: colors.WHITE, size: 16 }}
                label={selectedAccountAvailable ? "Switch Mode" : "Upgrade"} />
        </div>
    )
}

export default SwitchAccounts

type AccountType = {
    label: string;
    description: string;
    icon: keyof typeof Icons;
    plan_type: Plan
}

const accountTypes: AccountType[] = [
    {
        label: 'Event Management Dashboard',
        description: 'Get live metrics and statistics on your events',
        icon: 'event_account',
        plan_type: 'event_manager'
    },
    {
        label: 'Corporate Management Dashboard',
        description: 'Get live metrics and statistics on your organization',
        icon: 'corporate_account',
        plan_type: 'corporate_manager'
    },
    {
        label: 'Personal Dashboard',
        description: 'Get live metrics and statistics on your Carbon Games account',
        icon: 'personal_account',
        plan_type: 'personal'
    }
]