import { collection, getCountFromServer, getDocs, query, where } from "firebase/firestore"
import { firestoreDb } from "../config/firebase"
import { useQuery } from "react-query"
import useDestination from "./useDestination"
import { Carpool } from "../types/Carpool"
import { useAuth } from "../providers/AuthProvider"

const carpoolsCol = collection(firestoreDb, 'carpools')

const useStats = () => {

    const { userData } = useAuth()

    return {
        useCarpoolCountForDestinations: (destinationIds: string[]) => useQuery({
            queryKey: ['carpool-count-for-destination', destinationIds],
            queryFn: async () => {
                const qs = destinationIds.map(destinationId => query(carpoolsCol, where('destination.id', '==', destinationId)))
                return (await Promise.all(qs.map(async q => (await getCountFromServer(q)).data().count))).reduce((t, c) => t + c, 0)
            }
        }),
        useBasesStats: (destinationIds: string[]) => useQuery({
            queryKey: ['basic-stats', destinationIds],
            queryFn: async () => {
                const qs = destinationIds.map(destinationId => query(carpoolsCol, where('destination.id', '==', destinationId)))
                const carpoolDocs = (await Promise.all(qs.map(async q => (await getDocs(q)).docs))).reduce((t, c) => [...t, ...c], [])
                const carpools = carpoolDocs.map(doc => new Carpool({ ...doc.data(), id: doc.id }, userData?.id))

                const allPassengers = carpools.map(carpool => carpool.passengersArray).reduce((t, c) => [...t, ...c], [])

                return { 
                    totalNumberOfCarpools: carpools.length,
                    numberOfUniquePassengers : allPassengers.filter((p, i, arr)=>arr.map(p=>p.id).indexOf(p.id)===i).length
                  }
            }
        })
    }
}

export default useStats