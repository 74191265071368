import humanizeDuration from "humanize-duration"
import { Coordinate } from "../types/Location";

declare global {
    interface String {
        capitalizeEveryWord(): string
        capitalizeFirstLetter(): string
        generateColor(): string | undefined
        getInitials(): string | null
    }

    interface Number {
        formatDuration(params?: any): string;
    }
}

//strings
String.prototype.capitalizeEveryWord = function () {
    const words = this.valueOf()?.split(" ");
    return words.map((word) => {
        return word?.[0]?.toUpperCase() + word?.substring(1);
    })?.join(" ");
}

String.prototype.capitalizeFirstLetter = function () {
    return this.valueOf() ? this.valueOf().charAt(0).toUpperCase() + this.valueOf().slice(1) : 'null'
}

String.prototype.generateColor = function () {
    if (!this.valueOf()?.trim()) return undefined
    var colors = ["#B9BE95", "#D4883A", "#FF7852", "#42495B", "#C7BE95"];
    var sum = 0;
    for (var i = 0; i < this.valueOf().length; i++) {
        sum += this.valueOf().charCodeAt(i);
    }
    return colors[sum % colors.length]
}

String.prototype.getInitials = function () {
    const name = this.valueOf()
    if (!name) return null
    return name.split(' ').map(str => str.charAt(0).toUpperCase()).join().replaceAll(/,/g, '')
}

//numbers
Number.prototype.formatDuration = function (params = {}) {
    return humanizeDuration(this.valueOf() * 1000, {
        conjunction: ' and ',
        largest: 1,
        ...params
    })
}



export const getPropValue = function (sourceObject: any, dotNotationPath: string) {
    let returnData = sourceObject;

    dotNotationPath.split(".").forEach(subPath => {
        returnData = returnData?.[subPath]
    });

    return returnData;
}

export const coordinateToLatLng = (coordinate?: Coordinate) => {
    if (!coordinate) return { lat: 0, lng: 0 }
    return { lat: coordinate.latitude, lng: coordinate.longitude }
}

export const cleanObject = <TObj,>(obj: TObj & { [key: string]: any }) => {
    if (!obj) return obj
    const clone = { ...obj }
    for (let index = 0; index < Object.keys(clone).length; index++) {
        const key = Object.keys(clone)[index];
        if (clone[key] === undefined) delete obj[key]
    }
    return obj
}

export const convertToId = (str?: string) => {
    if (!str) return ''
    return str.replace(/\W/g, '_').toLowerCase()
}