import { createGlobalState } from "react-use"
import { db, functions } from "../config/firebase"
import { Carpool } from "../types/Carpool"
import useFirestoreRealtimeData from "./useFirestoreRealtimeData"
import useEvent from "./useEvent"
import useFirestoreRealtimeDoc from "./useFirestoreRealtimeDoc"
import { useAuth } from "../providers/AuthProvider"
import { useQuery } from "react-query"

const carpoolsColRef = db.collection('carpools')



export const useSelectedCarpool = createGlobalState<Carpool | undefined>()

const useCarpool = () => {

    const { slugEvent } = useEvent()
    const { userData } = useAuth()

    return {

        useAllActiveCarpools: <TData = Carpool[]>(transform: (data: Carpool[]) => TData = d => d as unknown as TData) => useFirestoreRealtimeData({
            query: carpoolsColRef.where('status', 'in', ['open', 'journey', 'pickups']).limit(20),
            select: (snapshot) => {
                const carpools = snapshot?.docs?.map(doc => new Carpool({ id: doc.id, ...doc.data() }, 'user_id')) || []
                return transform(carpools)
            },
        }),

        useCompletedCarpools: <TData = Carpool[]>(transform: (data: Carpool[]) => TData = d => d as unknown as TData) => useFirestoreRealtimeData({
            query: slugEvent ? carpoolsColRef.where('destination.id', '==', slugEvent.id).where('status', '==', 'completed').orderBy('createdAt', 'desc').limit(20) : carpoolsColRef.where('status', '==', 'completed').orderBy('createdAt', 'desc').limit(20),
            select: (snapshot) => {
                const carpools = snapshot?.docs?.map(doc => new Carpool({ id: doc.id, ...doc.data() }, 'user_id')) || []
                return transform(carpools)
            },
            resetOn: [slugEvent?.id]
        }),

        useVenueLeaderBoard: <TData = Carpool[]>(transform: (data: Carpool[]) => TData = d => d as unknown as TData) => useFirestoreRealtimeData({
            query: carpoolsColRef.where('status', '==', 'cancelled').orderBy('createdAt', 'desc').limit(20),
            select: (snapshot) => {
                const carpools = snapshot?.docs?.map(doc => new Carpool({ id: doc.id, ...doc.data() }, 'user_id')) || []
                return transform(carpools)
            },
        }),

        useCarpoolStats: () => useFirestoreRealtimeData({
            query: carpoolsColRef.where('createdAt', '>', (Date.now() - 1000 * 60 * 60 * 48)),
            select: (snapshot) => {
                const carpools = snapshot?.docs?.map(doc => new Carpool({ id: doc.id, ...doc.data() }, 'user_id'))
                const todayCarpools = carpools?.filter(carpool => carpool.createdAt > (Date.now() - 1000 * 60 * 60 * 24))
                const yesterdayCarpools = carpools?.filter(carpool => carpool?.createdAt < (Date.now() - 1000 * 60 * 60 * 24))
                return {
                    onlineCarpools: todayCarpools?.filter(carpool => carpool.status === 'open' || carpool.status === 'journey' || carpool.status === 'pickup').length,
                    offlineCarpools: todayCarpools?.filter(carpool => carpool.status === 'completed').length,
                    activeToday: (todayCarpools?.length || 0) - (yesterdayCarpools?.length || 0),
                    joinedToday: 0
                }
            },
        }),

        useRealtimeCarpool: (carpoolId?: string) => useFirestoreRealtimeDoc({
            query: carpoolsColRef.doc(carpoolId || ''),
            select: doc => new Carpool(doc?.data(), userData?.id),
            deps: [carpoolId]
        }),

        useCarpoolPreview: (carpoolId?: string, carpool?: Carpool) => useQuery({
            queryKey: ['carpool-preview', carpoolId, carpool?.passengersArray?.map(p => p.status)],
            queryFn: async () => await functions.httpsCallable('getCarpoolPreview')({ carpoolId }),
            enabled: !!carpoolId,
            select: data => new Carpool(data.data, userData?.id),
            keepPreviousData: true
        }),

    }
}

export default useCarpool 